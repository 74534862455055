import React, { useState } from 'react'
import loadable from '@loadable/component'
import * as styles from './RangeFilter.module.scss'
import { Props } from './types'
import classnames from 'classnames'
import { Box, Flex } from 'reflexbox'
import { PlusIcon } from 'src/components/UI/Icon/Icon'
// import RangeControl from 'src/components/UI/RangeControl/RangeControl'
const RangeControl = loadable(
  () => import('../../UI/RangeControl/RangeControl')
)

//TODO Fix functionality for this one
const RangeFilter = ({
  min,
  max,
  facetKey,
  setFilter,
  activeItems,
  name,
}: Props) => {
  const [isOpen, setOpen] = useState(false)

  return (
    <Box
      paddingBottom={[2, null, null, 2]}
      className={classnames(styles.filters, isOpen ? styles.active : '')}
    >
      <Flex
        justifyContent="space-between"
        paddingTop={[5, null, null, 5]}
        paddingBottom={[2, null, null, 2]}
        className={classnames(styles.title, styles.toggle)}
        width={1}
        as="button"
        onClick={() => {
          setOpen(!isOpen)
        }}
      >
        <Box as="span">{name}</Box>
        <PlusIcon className={styles.icon} />
      </Flex>
      <Flex
        paddingTop={['10px', null, null, '10px']}
        paddingBottom={['10px', null, null, '10px']}
        paddingX={'6px'}
        flexDirection="column"
        className={styles.container}
      >
        <RangeControl
          defaultMin={activeItems.length > 1 ? activeItems[0] : min}
          defaultMax={activeItems.length > 1 ? activeItems[1] : max}
          min={min}
          max={max}
          value={min}
          setFilter={setFilter}
        />
      </Flex>
    </Box>
  )
}

export default RangeFilter
