export const sortArrayByObjectKey = (
  arr: [],
  key: string,
  hasContent = false
) => {
  let sortedArray: any[] = []

  if (arr?.length > 0) {
    sortedArray = arr.sort((a: any, b: any) => {
      if (hasContent && a.content && b.content) {
        a.content[key]
        if (a.content[key] < b.content[key]) {
          return -1
        }
        if (a.content[key] > b.content[key]) {
          return 1
        }
        return 0
      } else {
        if (a[key] < b[key]) {
          return -1
        }
        if (a[key] > b[key]) {
          return 1
        }
        return 0
      }
    })
  }
  return sortedArray
}

export const sortArrayByString = (arr: [], key: string) => {
  let sortedArray: any[] = []

  if (arr?.length > 0) {
    sortedArray = arr.sort(function (a, b) {
      if (a[key] < b[key]) {
        return -1
      }
      if (a[key] > b[key]) {
        return 1
      }
      return 0
    })
  }

  return sortedArray
}
