import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import YouTube from 'react-youtube'
import classnames from 'classnames'

import { PlayIcon } from 'src/components/UI/Icon/Icon'
import Image from 'src/components/UI/Image/Image'

import * as styles from './YouTubeVideo.module.scss'
import { Props } from './types'

const YouTubeVideo = ({ id, image, className }: Props) => {
  const [videoActive, setVideoActive] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(image ? false : true)
  let videoTarget: any = null

  const opts = {
    height: '100%',
    width: '100%',
  }

  const onVideoReady = (e: any) => {
    videoTarget = e.target
  }

  const onClick = () => {
    setVideoActive(true)
    if (videoTarget) {
      videoTarget.playVideo()
    }
  }

  return id ? (
    <Box className={classnames(styles.videoWrapper, className)} width={1}>
      {!videoActive && (
        <Flex
          className={styles.poster}
          alignItems="center"
          justifyContent="center"
          onClick={onClick}
        >
          <Flex
            className={styles.playButton}
            alignItems="center"
            justifyContent="center"
          >
            <PlayIcon />
          </Flex>
          {image ? (
            <Image
              src={image}
              sizes={'100vw'}
              alt={image.title}
              onImageLoaded={() => {
                setTimeout(() => {
                  setImageLoaded(true)
                }, 800) // Set to same animation time as .o-image in global.scss
              }}
            />
          ) : (
            <img
              src={`https://img.youtube.com/vi/${id}/0.jpg`}
              alt={image.title}
            />
          )}
        </Flex>
      )}
      {imageLoaded && (
        <YouTube onReady={onVideoReady} opts={opts} videoId={id} />
      )}
    </Box>
  ) : null
}

export default YouTubeVideo
