import React, { useEffect, useRef, useState } from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import YouTubeVideo from 'src/components/YouTubeVideo/YouTubeVideo'
import VimeoVideo from 'src/components/VimeoVideo/VimeoVideo'

import * as styles from './QuoteAndVideo.module.scss'
import { Props } from './types'

const QuoteAndVideo = ({ blok }: Props): JSX.Element => {
  const [show, setShow] = useState(false)
  const { label, title, cover_image, video_service, video_source } = blok

  const elRef = useRef(null)
  let observer: any = null

  const observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting && observer && !show) {
        setShow(true)
      }
    }
  }

  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined' && !observer) {
      observer = new IntersectionObserver(observeHandler, {
        rootMargin: '0px 0px 500px 0px',
      })

      if (elRef && elRef.current) {
        observer.observe(elRef.current)
      }
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <SbEditable content={blok}>
      <Flex
        ref={elRef}
        className={styles.wrapper}
        flexDirection={['column', null, null, 'row']}
      >
        <Box width={[1, null, null, 1 / 2]}>
          <Flex
            className={styles.quoteContent}
            paddingX={[4, null, null, 20]}
            paddingY={[25, null, null, 0]}
            flexDirection="column"
            justifyContent="center"
          >
            {label && (
              <Box className={styles.label} marginBottom={[4, null, null, 2]}>
                {label}
              </Box>
            )}
            {title && (
              <Box className={styles.quote} as="blockquote">
                {title}
              </Box>
            )}
          </Flex>
        </Box>
        <Box width={[1, null, null, 1 / 2]} className={styles.videoWrapper}>
          {show ? (
            <div
              className={
                show
                  ? [styles.videoFade, styles.load].join(' ')
                  : styles.videoFade
              }
            >
              {video_service === 'youtube' && video_source && (
                <YouTubeVideo
                  className={styles.video}
                  id={video_source}
                  image={
                    cover_image && cover_image.filename
                      ? cover_image.filename
                      : ''
                  }
                />
              )}
              {video_service === 'vimeo' && video_source && (
                <VimeoVideo
                  className={styles.video}
                  source={video_source}
                  image={
                    cover_image && cover_image.filename
                      ? cover_image.filename
                      : ''
                  }
                />
              )}
            </div>
          ) : (
            <Box className={styles.video} />
          )}
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default QuoteAndVideo
