import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import ButtonText from 'src/components/UI/Button/ButtonText/ButtonText'

import * as styles from './ArticleHeader.module.scss'
import { Props } from './types'
import StoryblokVideo, {
  getMediaType,
} from 'src/storyblok/Components/StoryblokVideo/StoryblokVideo'

const ArticleHeader = ({
  title,
  image,
  preamble,
  category,
  tags,
  link,
}: Props): JSX.Element => {
  const { url, title: linkTitle } =
    link && link?.length > 0 ? getLinkAttributes(link[0]) : {}
  const mediaType = image ? getMediaType(image.filename) : ''
  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
      >
        <Box width={[1, null, null, 1 / 2]} order={[1, null, null, 1]}>
          {image && (
            <Box className={styles.imageWrapper} width={1}>
              {mediaType === 'video' ? (
                <StoryblokVideo src={image.filename} autoplay fill />
              ) : (
                image?.filename && (
                  <Image preload={true} src={image.filename} alt={title} />
                )
              )}
            </Box>
          )}
        </Box>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={[1, null, null, 1 / 2]}
          paddingX={[4, null, null, 6]}
          paddingY={[14, null, null, 0]}
          textAlign={['left', null, null, 'left']}
          order={[2, null, null, 1]}
        >
          <Flex
            width={[1, null, null, 4 / 6]}
            flexDirection="column"
            alignItems="flex-start"
          >
            <Box className={styles.tags} marginBottom={[1, null, null, 1]}>
              {category && <Box as="span">{category}</Box>}
              {tags?.length > 0 && <Box as="span">, {tags.join(', ')}</Box>}
            </Box>
            {title && (
              <Box className={styles.title} as="h1">
                {title}
              </Box>
            )}
            {preamble && (
              <Box marginTop={[6, null, null, 6]}>
                <Markdown source={preamble} />
              </Box>
            )}
            {url && linkTitle && (
              <Box marginTop={[5, null, null, 5]}>
                <ButtonText to={url}>{linkTitle}</ButtonText>
              </Box>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default ArticleHeader
