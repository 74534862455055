import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './SubNavigation.module.scss'
import { Props } from './types'

const SubNavigation = ({
  pages,
  activePage,
  rootPage,
  rootPageTitle,
}: Props): JSX.Element => {
  return pages?.length > 0 ? (
    <Box className={styles.wrapper} height={['97px', null, null, '73px']}>
      <Box className={styles.pages} height="100%">
        <Flex
          alignItems="center"
          justifyContent={['flex-start', null, null, 'center']}
          height="100%"
        >
          {pages.map((page: any) => {
            return page?.onClick ? (
              <Box
                key={`page-${page.slug}`}
                className={classnames(
                  styles.link,
                  activePage?.slug === page?.slug ? styles.active : ''
                )}
              >
                <Box as="button" onClick={page.onClick} title={page.title}>
                  {rootPage?.title &&
                  rootPage.title === page.title &&
                  rootPageTitle
                    ? rootPageTitle
                    : page.title}
                </Box>
              </Box>
            ) : (
              <Box
                key={`page-${page.slug}`}
                className={classnames(
                  styles.link,
                  activePage?.slug === page?.slug ? styles.active : ''
                )}
              >
                <LinkComponent to={page.slug} title={page.title}>
                  {rootPage?.title &&
                  rootPage.title === page.title &&
                  rootPageTitle
                    ? rootPageTitle
                    : page.title}
                </LinkComponent>
              </Box>
            )
          })}
        </Flex>
      </Box>
    </Box>
  ) : (
    <div />
  )
}

export default SubNavigation
