import React from 'react'
import SbEditable from 'storyblok-react'
import { Box, Flex } from 'reflexbox'

import Components from 'src/storyblok/Components'
import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import { getDataSourceEntryName } from 'src/helpers/getDataSourceData'

import ArticleHeader from './ArticleHeader/ArticleHeader'
import { Props } from './types'

const PageArticlesLanding = (props: Props): JSX.Element => {
  const { blok, full_slug } = props

  const { title, category, tags, preamble, image, content, link } = blok

  const { seo_data } = blok
  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    title: title || '',
    description: preamble || '',
    image: image?.filename ? image?.filename : '',
  })

  const categoryName = category ? getDataSourceEntryName(category) : ''
  const tagNames =
    tags && tags.length > 0
      ? tags.map((tag) => getDataSourceEntryName(tag))
      : ''
  return (
    <SbEditable content={blok}>
      <Box>
        <SEO
          url={full_slug}
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
        />
        <ArticleHeader
          title={title}
          category={categoryName}
          tags={tagNames}
          preamble={preamble}
          image={image}
          link={link}
          linkToSection={false}
        />
        {content?.length > 0 &&
          content.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
      </Box>
    </SbEditable>
  )
}

export default PageArticlesLanding
