import React from 'react'
import { Box, Flex } from 'reflexbox'
import { SplideSlide } from '@splidejs/react-splide'
import SbEditable from 'storyblok-react'

import { parseStoryblokArticleToAlgolia } from 'src/algolia/clienthelpers'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import ArticleCard from 'src/components/ArticleCard/ArticleCard'
import Markdown from 'src/components/Markdown/Markdown'
import ButtonText from 'src/components/UI/Button/ButtonText/ButtonText'
import Carousel from 'src/components/Carousel/Carousel'
import { Container } from 'src/components/UI/Grid/Grid'
import { getColor } from 'src/helpers/getColor'

import { Props } from './types'
import * as styles from './ArticleList.module.scss'

const ArticleList = ({ blok }: Props): JSX.Element => {
  const { title, link, articles, show_preambles, show_labels, theme } = blok

  const { url, title: linkTitle } =
    link && link?.length > 0 ? getLinkAttributes(link[0]) : {}

  const background = getColor(theme)

  const ratios = ['110%', '129.9%', '100%']
  let ratioIndex = 0
  return (
    <SbEditable content={blok}>
      <Flex
        className={styles.wrapper}
        bg={background}
        paddingTop={[15, null, null, 20]}
        paddingBottom={[18, null, null, 25]}
        flexDirection="column"
        width={1}
        as="section"
      >
        <Container>
          <Flex
            marginBottom={[8, null, null, 12]}
            justifyContent="space-between"
            alignItems="flex-end"
            width={1}
          >
            <Box
              className={styles.title}
              as="h3"
              width={[linkTitle && url ? 3 / 6 : 'auto', null, null, 'auto']}
            >
              <Markdown source={title} />
            </Box>
            {linkTitle && url && (
              <Flex
                justifyContent="flex-end"
                width={[3 / 6, null, null, 'auto']}
              >
                <ButtonText to={url}>{linkTitle}</ButtonText>
              </Flex>
            )}
          </Flex>
        </Container>
        <Box width={1} className={styles.slider}>
          <Box
            marginLeft={[5, null, null, `${(1 / 12) * 100}%`]}
            width={[4 / 6, null, null, 3 / 12]}
          >
            <Carousel preventLastScroll>
              {articles &&
                articles.map((article: any, index: number) => {
                  const ratio = ratios[ratioIndex]
                  ratioIndex++
                  if (ratioIndex > 2) {
                    ratioIndex = 0
                  }
                  const parsedArticle = parseStoryblokArticleToAlgolia(article)
                  return parsedArticle?.id ? (
                    <SplideSlide key={index}>
                      <ArticleCard
                        showPreamble={show_preambles}
                        showLabel={show_labels}
                        ratio={ratio}
                        article={parsedArticle}
                      />
                    </SplideSlide>
                  ) : null
                })}
            </Carousel>
          </Box>
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default ArticleList
