import React from 'react'
import SbEditable from 'storyblok-react'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import * as styles from './BookingForm.module.scss'
import { Props } from './types'

const BookingForm = ({ blok }: Props): JSX.Element => {
  return (
    <SbEditable content={blok}>
      <Container
        className={styles.wrapper}
        paddingTop={[40, null, null, 20]}
        paddingBottom={[40, null, null, 20]}
        paddingX={[3, null, null, 6]}
      >
        <Row flexDirection="row" justifyContent="center" alignItems="center">
          <Column width={[1, null, null, 8 / 12]}>
            <div className={styles.form}>
              <iframe
                src="//sv-se.eu.invajo.com/events/widget/id/3616b9a0-b9b1-11ed-9f3b-894e1d5e73c1"
                className="invajo-event-widget"
                id="invajo-event-widget_3616b9a0-b9b1-11ed-9f3b-894e1d5e73c1"
                frameborder="0"
                height="733px"
                width="100%"
                vspace="0"
                hspace="0"
                marginheight="5"
                marginwidth="5"
                scrolling="yes"
                allowtransparency="true"
              ></iframe>
              <a
                href="https://invajo.com"
                style={{ display: 'none' }}
                title="Invajo"
              >
                Invajo.com
              </a>
            </div>
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default BookingForm
