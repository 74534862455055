import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Markdown from 'src/components/Markdown/Markdown'
import { Container } from 'src/components/UI/Grid/Grid'
import { getColor } from 'src/helpers/getColor'
import Image from 'src/components/UI/Image/Image'
import ButtonText from 'src/components/UI/Button/ButtonText/ButtonText'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'

import { Props } from './types'
import * as styles from './ClubBanner.module.scss'

const ClubBanner = ({ blok }: Props): JSX.Element => {
  const {
    label,
    title,
    link,
    text,
    image,
    image_label,
    image_text,
    theme,
  } = blok

  const background = getColor(theme)

  const { url, title: linkTitle } =
    link && link?.length > 0 ? getLinkAttributes(link[0]) : {}

  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        bg={background}
        width={1}
        as="section"
      >
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          paddingX={[4, null, null, 20]}
          paddingY={[30]}
          width={[1, null, null, 1 / 2]}
          className={styles.contentSection}
        >
          <Box marginBottom={[3]} className={styles.label} as="span">
            {label}
          </Box>
          <Box marginBottom={[6]} className={styles.title} as="h3">
            {title}
          </Box>
          <Box maxWidth="450px" marginBottom={[6]}>
            <Markdown source={text} />
          </Box>
          {linkTitle && url && <ButtonText to={url}>{linkTitle}</ButtonText>}
        </Flex>
        <Flex className={styles.imageWrapper} width={[1, null, null, 1 / 2]}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            paddingX={[4, null, null, 20]}
            className={styles.content}
          >
            {image_label && (
              <Box marginBottom={[2]} className={styles.label} as="span">
                {image_label}
              </Box>
            )}
            {image_text && (
              <Box className={styles.imageTitle} as="p">
                {image_text}
              </Box>
            )}
          </Flex>
          {image && (
            <Image
              src={image.filename}
              alt={image_label ? image_label : title}
            />
          )}
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default ClubBanner
