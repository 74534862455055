import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import PageNav from 'src/components/PageNav/PageNav'
import ProducerListCard from 'src/components/ProducerListCard/ProducerListCard'
import ListHeader from 'src/components/ListHeader/ListHeader'
import { getDataSourceEntryName } from 'src/helpers/getDataSourceData'
import Components from 'src/storyblok/Components'
import { sortArrayByObjectKey } from 'src/helpers/sortArray'

import { Props } from './types'
import * as styles from './PageCountry.module.scss'

const PageCountry = ({ blok, full_slug }: Props) => {
  const [showProducers, setShowProducers] = useState(true)
  const {
    header_image,
    description,
    regions,
    producers,
    country,
    content,
    seo_data,
  } = blok

  const sortedProducers = sortArrayByObjectKey(producers, 'name')
  const sortedRegions = sortArrayByObjectKey(regions, 'name')

  const activeItems = showProducers ? sortedProducers : sortedRegions

  const title = getDataSourceEntryName(country)

  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    title: title || '',
    description: description || '',
    image: header_image?.filename || '',
  })
  return (
    <SbEditable content={blok}>
      <Flex width={1} flexDirection="column">
        <SEO
          url={full_slug}
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
        />
        <ListHeader
          title={title}
          description={description}
          image={header_image}
          slug={`/shoppen/#country=${country}`}
          linkText={`Upptäck alla viner från ${title}`}
        />

        <PageNav
          mobileCenter
          items={[
            {
              name: 'Visa Regioner',
              active: !showProducers,
              onClick: () => {
                if (showProducers) {
                  setShowProducers(false)
                }
              },
            },
            {
              name: 'Visa Producenter',
              active: showProducers,
              onClick: () => {
                if (!showProducers) {
                  setShowProducers(true)
                }
              },
            },
          ]}
        />
        <Flex className={styles.grid} width={1} flexWrap="wrap">
          {activeItems?.length > 0 &&
            activeItems.map((item: any, index: number) => (
              <Box
                key={`${item.slug}-${index}`}
                width={[1 / 2, null, null, 1 / 3]}
              >
                <ProducerListCard
                  name={item.name}
                  slug={item.full_slug}
                  background={item.content.list_background}
                  image={item.content.list_image}
                  logotype={item.content.logotype}
                />
              </Box>
            ))}
        </Flex>

        {content?.length > 0 &&
          content.map((blok: any) => (
            <Box key={blok._uid}>
              {React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })}
            </Box>
          ))}
      </Flex>
    </SbEditable>
  )
}

export default PageCountry
