import React from 'react'
import { Splide } from '@splidejs/react-splide'
import classnames from 'classnames'

import * as styles from './Slider.module.scss'
import { Props } from './types'

// Childs need to be wrapped by SplideSlide
const Slider = ({ children, className }: Props): JSX.Element => {
  if (typeof window === 'undefined') {
    return <p>Server Render</p>
  }
  return (
    <Splide
      className={classnames(styles.wrapper, className)}
      options={{
        type: 'loop',
        perPage: 1,
        pagination: true,
        arrows: false,
        autoplay: 'pause',
        interval: 6000,
      }}
    >
      {children}
    </Splide>
  )
}

export default Slider
