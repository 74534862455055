import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Components from 'src/storyblok/Components'
import { AboutProduct } from 'src/components/Product/AboutProduct/AboutProduct'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'

// import * as styles from './GlobalProduct.module.scss'
import { Props } from './types'

const GlobalProduct = ({ blok }: Props): JSX.Element => {
  const { about_image, video } = blok
  return (
    <SbEditable content={blok}>
      <AboutProduct image={about_image} />
      {video?.length > 0 &&
        video.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}
    </SbEditable>
  )
}

export default GlobalProduct
