import React, { useEffect, useRef, useState } from 'react'
import { Box, Flex } from 'reflexbox'

import { getVolumeInMillieLiters } from 'src/helpers/getVolumeInMillieLiters'

import LinkComponent from '../LinkComponent/LinkComponent'
import { ChevronDownIcon } from '../UI/Icon/Icon'
import * as styles from './Sizepicker.module.scss'
import { Props } from './types'

const Sizepicker = ({ sizes, activeSize }: Props) => {
  const wrapperRef = useRef(null)
  const [isOpen, setOpen] = useState(false)

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const activeSizeInMillieLiters = getVolumeInMillieLiters(activeSize)
  return (
    <Box
      ref={wrapperRef}
      marginRight={[2, null, null, 4]}
      className={
        isOpen ? [styles.wrapper, styles.active].join(' ') : styles.wrapper
      }
      width={[2 / 6, null, null, '122px']}
      height="48px"
    >
      <Flex
        width={1}
        height="100%"
        paddingX={2}
        className={styles.button}
        textAlign="center"
        alignItems="center"
        justifyContent="space-between"
        as="button"
        onClick={() => setOpen(!isOpen)}
      >
        <Box>{activeSizeInMillieLiters} ml</Box>
        <Box>
          <ChevronDownIcon />
        </Box>
      </Flex>
      <Flex
        width={1}
        flexDirection="column"
        alignItems="flex-start"
        className={
          isOpen
            ? [styles.itemWrapper, styles.active].join(' ')
            : styles.itemWrapper
        }
      >
        {sizes &&
          sizes.length > 0 &&
          sizes.map((size: any, index: number) => {
            const volumeInMillieLiters = getVolumeInMillieLiters(size.size)
            return volumeInMillieLiters ? (
              <LinkComponent
                key={`size-${index}`}
                className={styles.item}
                to={size.slug}
              >
                <Box>{volumeInMillieLiters} ml</Box>
                <Box></Box>
              </LinkComponent>
            ) : null
          })}
      </Flex>
    </Box>
  )
}

export default Sizepicker
