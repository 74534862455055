import { Link } from 'gatsby'
import React from 'react'
import { Box, Flex } from 'reflexbox'
import { useRecoilState } from 'recoil'
import classnames from 'classnames'

import Badge from 'src/components/UI/Badge/Badge'
import { topListState } from 'src/recoil/atoms'
import ProductLabel from 'src/components/ProductCard/ProductLabel/ProductLabel'
import Image from 'src/components/UI/Image/Image'
import { getTopListPosition } from 'src/helpers/getToplistPosition'

import * as styles from './ImageSection.module.scss'
import { Props } from './types'

export const ImageSection = ({
  labels,
  categoryTree,
  image,
  name,
  quote,
  id,
  ratings,
  badge,
}: Props): JSX.Element => {
  const [topList] = useRecoilState(topListState)
  const topListPosition = topList ? getTopListPosition(topList, id) : null

  return (
    <Box className={styles.imageSection} width={[1, null, null, 1 / 2]}>
      <Flex
        paddingX={[4, null, null, 6]}
        className={classnames(styles.labels, 'hidePrint')}
        flexDirection="column"
      >
        {labels &&
          labels.length > 0 &&
          labels.map((label: any, i: number) => (
            <ProductLabel
              rounded={true}
              key={`goes-well-with-tag-${i}`}
              type={label.value}
            >
              {label.name}
            </ProductLabel>
          ))}
      </Flex>
      {ratings?.length > 0 && (
        <Flex
          flexDirection="column"
          alignItems="flex-end"
          className={classnames(styles.ratings, 'hidePrint')}
          paddingX={[4, null, null, 6]}
        >
          {ratings.map((rating: any) => {
            const { filename, alt } = rating?.image || {}
            return filename ? (
              <Box key={filename} className={styles.rating} marginBottom={[4]}>
                <Image
                  src={filename}
                  alt={alt ? alt : ''}
                  sizes={{
                    mobile: '50vw',
                    tablet: '33vw',
                    desktop: '33vw',
                  }}
                />
              </Box>
            ) : null
          })}
        </Flex>
      )}
      <Flex
        alignItems="center"
        className={classnames(styles.breadcrumbs, 'hidePrint')}
        height={'44px'}
        paddingX={[4, null, null, 6]}
      >
        <Link to="/shoppen">Våra viner</Link>
        <Box marginX={1} as="span">
          {' / '}
        </Box>
        {categoryTree && categoryTree.id && (
          <React.Fragment>
            <Link to={`${categoryTree.slug}`}>{categoryTree.name}</Link>
            <Box marginX={1} as="span">
              {' / '}
            </Box>
          </React.Fragment>
        )}
        {categoryTree &&
          categoryTree.id &&
          categoryTree.subItems &&
          categoryTree.subItems.length > 0 && (
            <React.Fragment>
              <Link to={`${categoryTree.subItems[0].slug}`}>
                {categoryTree.subItems[0].name}
              </Link>
              <Box marginX={1} as="span">
                {' / '}
              </Box>
            </React.Fragment>
          )}
        <Box as="span">{name}</Box>
      </Flex>
      <Flex
        paddingTop={[11]}
        paddingX={[4, null, null, 6]}
        width={1}
        className={styles.content}
        flexDirection="column"
        alignItems="center"
        justifyContent="flex-start"
        textAlign="center"
      >
        <Box className={styles.imageContainer}>
          {(topListPosition || badge) && (
            <Badge
              className={classnames(styles.badge, 'hidePrint')}
              position={topListPosition}
              badge={badge}
            />
          )}
          {image && (
            <Image
              className={styles.image}
              // ratio={382 / 478}
              src={image}
              alt={name}
              preload={true}
            />
          )}
        </Box>
        {quote && (
          <Box
            maxWidth="410px"
            className={classnames(
              styles.productQuote,
              'hideMobile',
              'hidePrint'
            )}
          >
            <Box as="blockquote">{`“${quote}”`}</Box>
          </Box>
        )}
      </Flex>
    </Box>
  )
}
