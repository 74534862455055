import React from 'react'
import SbEditable from 'storyblok-react'

import Markdown from 'src/components/Markdown/Markdown'

const Textarea = ({ blok }: any) => (
  <SbEditable content={blok}>
    <Markdown source={blok.text} />
  </SbEditable>
)

export default Textarea
