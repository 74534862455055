import React from 'react'
import { Box, Flex } from 'reflexbox'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import Image from '../UI/Image/Image'
import * as styles from './ArticleHighlight.module.scss'
import { Props } from './types'

const ArticleHighlight = ({ article, label }: Props): JSX.Element => {
  const { slug } = article
  const { image, title } = article
  return (
    <Flex className={styles.wrapper} flexDirection="column" alignItems="center">
      {label && (
        <Box className={styles.label} marginBottom={[3, null, null, 3]}>
          {label}
        </Box>
      )}
      <LinkComponent to={`/${slug}`}>
        {image?.filename && (
          <Box className={styles.image} marginBottom={[5]}>
            <Image
              src={image.filename}
              alt={title}
              sizes={{
                mobile: '50vw',
                tablet: '33vw',
                desktop: '33vw',
              }}
            />
          </Box>
        )}
        {title && (
          <Box className={styles.title} as="span">
            {title}
          </Box>
        )}
      </LinkComponent>
    </Flex>
  )
}

export default ArticleHighlight
