import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Markdown from 'src/components//Markdown/Markdown'
import Image from 'src/components//UI/Image/Image'

import * as styles from './ArticleItem.module.scss'
import { Props } from './types'

const ArticleItem = ({
  article,
  ratio = '66%',
  showTags = false,
  showPreamble = false,
  showPreambleMobile = false,
}: Props): JSX.Element => {
  const { slug } = article
  const { image, list_image, title, preamble, categoryName, tagNames } = article

  return (
    <Box className={styles.wrapper}>
      <LinkComponent to={`/${slug}`}>
        <Box className={styles.imageWrapper}>
          <Box width={1} paddingTop={ratio} />
          {image && image.filename ? (
            <Image
              src={image.filename}
              alt={title}
              sizes={{
                mobile: '50vw',
                tablet: '33vw',
                desktop: '33vw',
              }}
            />
          ) : list_image && list_image.filename ? (
            <Image
              src={list_image.filename}
              alt={title}
              sizes={{
                mobile: '50vw',
                tablet: '33vw',
                desktop: '33vw',
              }}
            />
          ) : null}
        </Box>
        <Box paddingTop={[6, null, null, 6]} textAlign="center">
          <Box marginBottom={[1, null, null, 1]} className={styles.tags}>
            {showTags && tagNames?.length > 0 ? (
              <Box>
                {tagNames.map(
                  (tag, index) =>
                    `${tag}${index < tagNames.length - 1 ? `, ` : ``}`
                )}
              </Box>
            ) : (
              <Box>{categoryName}</Box>
            )}
          </Box>
          {title && (
            <Box
              as="h2"
              className={styles.heading}
              paddingX={[
                showPreambleMobile ? 4 : 0,
                null,
                null,
                (1 / 8) * 100 + '%',
              ]}
            >
              {title}
            </Box>
          )}
          {preamble && (
            <Box
              marginTop={[4, null, null, 4]}
              paddingX={[
                showPreambleMobile ? 4 : 0,
                null,
                null,
                (2 / 8) * 100 + '%',
              ]}
              display={[
                showPreambleMobile ? 'block' : 'none',
                null,
                null,
                showPreamble ? 'block' : 'none',
              ]}
            >
              <Markdown source={preamble} />
            </Box>
          )}
        </Box>
      </LinkComponent>
    </Box>
  )
}

export default ArticleItem
