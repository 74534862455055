import React, { useEffect, useRef, useState } from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import * as styles from './TasteProfile.module.scss'
import { Props } from './types'

const TasteProfile = ({ blok }: Props): JSX.Element => {
  const { label, taste } = blok
  const elRef = useRef(null)

  const circleConst = 132
  const circlePart = circleConst / 12

  const [animateStats, setAnimateStats] = useState(false)

  let observer: any = null

  const observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting && observer) {
        setTimeout(() => {
          setAnimateStats(true)
        }, 500)
      } else {
        setAnimateStats(false)
      }
    }
  }

  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined' && !observer) {
      observer = new IntersectionObserver(observeHandler, {
        rootMargin: '0px 0px 0px 0px',
      })

      if (elRef && elRef.current) {
        observer.observe(elRef.current)
      }
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <SbEditable content={blok}>
      <Flex
        ref={elRef}
        className={styles.wrapper}
        flexDirection="column"
        alignItems="center"
      >
        <Flex alignItems="center" className={styles.chart}>
          <svg>
            <circle className={styles.fill} r="21" cx="30" cy="30"></circle>
            <circle
              strokeDashoffset={
                !animateStats
                  ? circleConst
                  : circleConst - circlePart * parseInt(taste)
              }
              className={styles.progress}
              r="21"
              cx="30"
              cy="30"
            ></circle>
          </svg>
        </Flex>
        <Box className={styles.label} marginTop={[4, null, null, 4]}>
          {label}
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default TasteProfile
