import React, { useEffect, useRef, useState } from 'react'
import { Box } from 'reflexbox'
import classnames from 'classnames'

import { Props } from './types'
import * as styles from './Badge.module.scss'

const Badge = ({ position, badge, className }: Props): JSX.Element => {
  const [show, setShow] = useState(false)
  const wrapperClassName = classnames(
    styles.wrapper,
    className,
    show ? styles.show : ''
  )

  const elRef = useRef(null)
  let observer: any = null

  const observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting && observer && !show) {
        setShow(true)
      }
    }
  }

  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined' && !observer) {
      observer = new IntersectionObserver(observeHandler, {
        rootMargin: '0px 0px 0px 0px',
      })

      if (elRef && elRef.current) {
        observer.observe(elRef.current)
      }
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  return (
    <Box ref={elRef} className={wrapperClassName}>
      {position && show ? (
        <React.Fragment>
          {position === 1 ? (
            <TopList1 />
          ) : position === 2 ? (
            <TopList2 />
          ) : position === 3 ? (
            <TopList3 />
          ) : position === 4 ? (
            <TopList4 />
          ) : position === 5 ? (
            <TopList5 />
          ) : position === 6 ? (
            <TopList6 />
          ) : position === 7 ? (
            <TopList7 />
          ) : position === 8 ? (
            <TopList8 />
          ) : position === 9 ? (
            <TopList9 />
          ) : position === 10 ? (
            <TopList10 />
          ) : null}
        </React.Fragment>
      ) : show ? (
        <React.Fragment>
          {badge === 'top_review' ? (
            <TopReview />
          ) : badge === 'top_season' ? (
            <TopSeason />
          ) : badge === 'iconic' ? (
            <Iconic />
          ) : badge === 'sustainable' ? (
            <Sustainable />
          ) : null}
        </React.Fragment>
      ) : null}
    </Box>
  )
}

export default Badge

const Iconic = (): JSX.Element => <img src="/badges/iconic.svg" alt="Iconic" />

const TopSeason = (): JSX.Element => (
  <img src="/badges/top-season.svg" alt="Top season" />
)

const TopReview = (): JSX.Element => (
  <img src="/badges/top-review.svg" alt="Top review" />
)

const Sustainable = (): JSX.Element => (
  <img src="/badges/sustainable.svg" alt="Sustainable" />
)

const TopList1 = (): JSX.Element => (
  <img src="/badges/toplist-1.svg" alt="Toplist #1" />
)

const TopList2 = (): JSX.Element => (
  <img src="/badges/toplist-2.svg" alt="Toplist #2" />
)

const TopList3 = (): JSX.Element => (
  <img src="/badges/toplist-3.svg" alt="Toplist #3" />
)

const TopList4 = (): JSX.Element => (
  <img src="/badges/toplist-4.svg" alt="Toplist #4" />
)

const TopList5 = (): JSX.Element => (
  <img src="/badges/toplist-5.svg" alt="Toplist #5" />
)

const TopList6 = (): JSX.Element => (
  <img src="/badges/toplist-6.svg" alt="Toplist #6" />
)

const TopList7 = (): JSX.Element => (
  <img src="/badges/toplist-7.svg" alt="Toplist #7" />
)

const TopList8 = (): JSX.Element => (
  <img src="/badges/toplist-8.svg" alt="Toplist #8" />
)

const TopList9 = (): JSX.Element => (
  <img src="/badges/toplist-9.svg" alt="Toplist #9" />
)

const TopList10 = (): JSX.Element => (
  <img src="/badges/toplist-10.svg" alt="Toplist #10" />
)
