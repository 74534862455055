// extracted by mini-css-extract-plugin
export var active = "ProductCard-module--active--2go6n";
export var badge = "ProductCard-module--badge--Egesc";
export var buyButton = "ProductCard-module--buyButton--I2fcc";
export var content = "ProductCard-module--content--gEsUi";
export var contentOverlay = "ProductCard-module--contentOverlay--iUW1x";
export var favoriteButton = "ProductCard-module--favoriteButton--eZolT";
export var imageWrapper = "ProductCard-module--imageWrapper--A3+HK";
export var information = "ProductCard-module--information--jIqRC";
export var location = "ProductCard-module--location--Ug+-0";
export var metadata = "ProductCard-module--metadata--FHbgH";
export var name = "ProductCard-module--name--zJ+-h";
export var price = "ProductCard-module--price--HIBW1";
export var producer = "ProductCard-module--producer--6mloJ";
export var rating = "ProductCard-module--rating--CnD5P";
export var ratings = "ProductCard-module--ratings--ZPXZr";
export var thumbnail = "ProductCard-module--thumbnail--fQ3tG";
export var wrapper = "ProductCard-module--wrapper--G6qBF";