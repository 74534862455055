import React, { useRef, useState } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { getDataSourceEntries } from 'src/helpers/getDataSourceData'
import { getColor } from 'src/helpers/getColor'
import Image from 'src/components/UI/Image/Image'
import ButtonBorder from 'src/components/UI/Button/ButtonBorder/ButtonBorder'
import WineSelect from 'src/components/UI/WineSelect/WineSelect'
import { categoryStructure } from 'src/helpers/getCategoryStructure'

import { Props } from './types'
import * as styles from './WineFinder.module.scss'

const WineFinder = ({ blok }: Props) => {
  const { label, image, theme } = blok
  const categories: any = []
  categoryStructure.forEach((cat: any) => {
    categories.push({ name: cat.name, value: cat.slug })
  })
  const food = getDataSourceEntries('goes-well-with')
  const [activeCat, setActiveCat] = useState(categories[0].value)
  const [activeFood, setActiveFood] = useState(food[0].value)
  const background = getColor(theme)

  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        bg={background}
        width={1}
        as="section"
      >
        <Flex className={styles.imageWrapper} width={[1, null, null, 1 / 2]}>
          {image && <Image src={image.filename} alt={label} />}
        </Flex>
        <Flex
          paddingX={[4, null, null, 4]}
          paddingY={[18, null, null, 30]}
          flexDirection="column"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          width={[1, null, null, 1 / 2]}
        >
          <Box marginBottom={[6]} as="span" className={styles.label}>
            {label}
          </Box>
          <Flex flexWrap="wrap" alignItems="center" marginBottom={[2]}>
            <Box marginRight={2} className={styles.text} as="span">
              Hjälp mig hitta ett
            </Box>
            <WineSelect
              defaultItem={categories[0]}
              name="catselector"
              onChange={(value: any) => {
                setActiveCat(value)
              }}
              items={categories}
            />
          </Flex>
          <Flex marginBottom={[6]}>
            <Box marginRight={2} as="span" className={styles.text}>
              Som passar till
            </Box>
            <WineSelect
              defaultItem={food[0]}
              name="foodselector"
              onChange={(value: any) => {
                setActiveFood(value)
              }}
              items={food}
            />
          </Flex>
          <ButtonBorder
            onClick={() => {
              if (window) {
                window.scrollTo(0, 0)
              }
            }}
            to={`${activeCat}#goes_well_with=${activeFood}`}
          >
            Hitta
          </ButtonBorder>
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default WineFinder
