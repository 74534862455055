// extracted by mini-css-extract-plugin
export var badge = "ImageSection-module--badge--gd1fW";
export var breadcrumbs = "ImageSection-module--breadcrumbs--+7nvn";
export var content = "ImageSection-module--content--J+hl0";
export var image = "ImageSection-module--image--aGKGU";
export var imageContainer = "ImageSection-module--imageContainer--GW7Ps";
export var imageSection = "ImageSection-module--imageSection--Zvjty";
export var labels = "ImageSection-module--labels--eSldX";
export var productQuote = "ImageSection-module--productQuote--fPyRW";
export var rating = "ImageSection-module--rating--Wykd3";
export var ratings = "ImageSection-module--ratings--3AgJE";