import React, { useState } from 'react'
import classnames from 'classnames'
import { Box, Flex } from 'reflexbox'

import { PlusIcon } from 'src/components/UI/Icon/Icon'
import { getDataSourceEntryName } from 'src/helpers/getDataSourceData'
import ButtonLink from 'src/components/UI/Button/ButtonLink/ButtonLink'

import { Props } from './types'
import * as styles from './MultiFilter.module.scss'

const MultiFilter = ({
  items,
  availableItems,
  facetKey,
  setFilter,
  activeItems,
  name,
  acceptAll,
}: Props): JSX.Element => {
  const maxVisible = 5
  const [isOpen, setOpen] = useState(false)
  const [showAllFilters, setShowAllFilters] = useState(false)

  const compare = (a: any, b: any) => {
    if (a.title < b.title) {
      return -1
    }
    if (a.title > b.title) {
      return 1
    }
    return 0
  }

  let values = Object.keys(items)
    .map((key: string) => ({
      value: key,
      title: getDataSourceEntryName(key),
    }))
    .sort(compare)

  if (availableItems && !acceptAll) {
    values = values.sort(function (x: any, y: any) {
      // true values first
      const xValue = availableItems[x.value] ? true : false
      const yValue = availableItems[y.value] ? true : false
      return xValue === yValue ? 0 : xValue ? -1 : 1
      // false values first
      // return (x === y)? 0 : x? 1 : -1;
    })
  }

  if (!showAllFilters) {
    values = values.slice(0, maxVisible)
  }

  return (
    <Box
      paddingBottom={[2, null, null, 2]}
      className={classnames(styles.filters, isOpen ? styles.active : '')}
      key={facetKey}
    >
      <Flex
        justifyContent="space-between"
        paddingTop={[5, null, null, 5]}
        paddingBottom={[2, null, null, 2]}
        className={classnames(styles.title, styles.toggle)}
        width={1}
        as="button"
        onClick={() => {
          setOpen(!isOpen)
        }}
      >
        <Box as="span">{name}</Box>
        <PlusIcon className={styles.icon} />
      </Flex>
      <Flex
        paddingBottom={['10px', null, null, '10px']}
        flexDirection="column"
        className={styles.container}
      >
        {values.length > 0 && (
          <React.Fragment>
            {values.map((item: any) => {
              return (
                <Box key={item.value} className={styles.filter}>
                  <input
                    id={item.value}
                    name={item.value}
                    className={styles.checkbox}
                    type="checkbox"
                    disabled={
                      !acceptAll &&
                      availableItems &&
                      !availableItems[item.value]
                        ? true
                        : false
                    }
                    onChange={() => {
                      setFilter({
                        facet: facetKey,
                        value: item.value,
                      })
                    }}
                    checked={
                      activeItems &&
                      activeItems.length > 0 &&
                      activeItems.includes(item.value)
                    }
                  />
                  <label htmlFor={item.value}>{item.title}</label>
                </Box>
              )
            })}
            {!showAllFilters && Object.keys(items).length > maxVisible && (
              <ButtonLink
                className={styles.showAllButton}
                onClick={() => setShowAllFilters(true)}
              >
                Visa fler
              </ButtonLink>
            )}
          </React.Fragment>
        )}
      </Flex>
      {showAllFilters && <div className={styles.gradient} />}
    </Box>
  )
}

export default MultiFilter
