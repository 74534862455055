import React, { useState } from 'react'
import SbEditable from 'storyblok-react'
import { Box, Flex } from 'reflexbox'

import { getDataSourceEntryValue } from 'src/helpers/getDataSourceData'
import { Article } from 'src/globalTypes/types'
import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import ArticleGrid from 'src/components/ArticleGrid/ArticleGrid'
import { getOrderByDataSource } from 'src/helpers/getOrderByDataSource'

import ArticleCategoryHeader from './ArticleCategoryHeader/ArticleCategoryHeader'
import { Props } from './types'
import ButtonBorder from 'src/components/UI/Button/ButtonBorder/ButtonBorder'
import * as styles from './PageArticleCategory.module.scss'

const PageArticleCategory = (props: Props): JSX.Element => {
  const { blok, full_slug } = props
  const PER_PAGE = 13
  const { image, title, preamble, seo_data } = blok
  const { articles, topCategories, tags, show_tags } = blok

  const [activeFilter, setActiveFilter] = useState('')
  const [activePage, setActivePage] = useState(1)
  let filteredArticles = articles

  if (activeFilter) {
    filteredArticles = articles.filter((article: Article) => {
      const tagValues = article.tagNames.map((tagName) =>
        getDataSourceEntryValue(tagName, 'article-tags')
      )

      return tagValues.includes(activeFilter)
    })
  }

  const handleClick = () => {
    setActivePage(activePage + 1)
  }

  const sortedArticles =
    filteredArticles?.length > 0
      ? getOrderByDataSource(
          filteredArticles,
          'article-categories',
          'category',
          [],
          false
        )
      : []

  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    title: title || '',
    description: preamble || '',
    image: image?.filename ? image?.filename : '',
  })

  return (
    <SbEditable content={blok}>
      <Box>
        <SEO
          url={full_slug}
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
        />
        <ArticleCategoryHeader
          title={title}
          image={image}
          preamble={preamble}
          topCategories={topCategories}
          tags={tags}
          fullSlug={full_slug}
          showTags={show_tags}
          activeFilter={activeFilter}
          setActiveFilter={setActiveFilter}
        />
        {sortedArticles?.length > 0 && (
          <ArticleGrid
            articles={sortedArticles.slice(0, PER_PAGE * activePage)}
            showTags={show_tags}
          />
        )}
        {sortedArticles.length > PER_PAGE && (
          <Flex
            justifyContent={'center'}
            alignItems={'center'}
            marginTop={'40px'}
            marginBottom={'40px'}
          >
            <ButtonBorder
              disabled={PER_PAGE * activePage > sortedArticles.length}
              className={styles.button}
              onClick={handleClick}
            >
              Ladda fler
            </ButtonBorder>
          </Flex>
        )}
      </Box>
    </SbEditable>
  )
}

export default PageArticleCategory
