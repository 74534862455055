import React from 'react'
import { Box, Flex } from 'reflexbox'

import LinkComponent from '../LinkComponent/LinkComponent'
import * as styles from './PageNav.module.scss'
import { Props } from './types'

const PageNav = ({ items, mobileCenter, useHash }: Props) => (
  <Flex
    paddingX={1}
    justifyContent={[
      mobileCenter ? 'center' : 'flex-start',
      null,
      null,
      'center',
    ]}
    alignItems="center"
    textAlign="center"
    height="73px"
    className={styles.nav}
  >
    {items &&
      items.length > 0 &&
      items.map((navItem: any, index: number) =>
        navItem.active ? (
          <Box
            key={`page-nav-${index}`}
            className={[styles.navItem, styles.active].join(' ')}
            as="span"
          >
            {navItem.name}
          </Box>
        ) : navItem.slug ? (
          <LinkComponent
            key={index}
            className={styles.navItem}
            to={navItem.slug}
          >
            {navItem.name}
          </LinkComponent>
        ) : (
          <Box
            key={index}
            onClick={() => {
              if (navItem.onClick) {
                navItem.onClick()
              }
              if (useHash) {
                if (history.pushState) {
                  history.pushState(null, null, `#page=${index}`)
                } else {
                  location.hash = `#page=${index}`
                }
              }
            }}
            className={styles.navItem}
            as="span"
          >
            {navItem.name}
          </Box>
        )
      )}
  </Flex>
)

export default PageNav
