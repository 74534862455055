import React from 'react'
import { Box } from 'reflexbox'

import LinkComponent from '../LinkComponent/LinkComponent'
import Image from '../UI/Image/Image'
import * as styles from './ProducerListCard.module.scss'
import { Props } from './types'

const getBackground = (background: string) => {
  switch (background) {
    case 'green':
      return '#a7b39b'
    case 'sand':
      return '#d9d1c5'
    case 'rose':
      return '#cca7a2'
    default:
      return '#cca7a2'
  }
}

const ProducerListCard = ({
  name,
  image,
  background,
  slug,
  logotype,
}: Props) => (
  <LinkComponent
    to={slug}
    className={styles.wrapper}
    style={{ background: getBackground(background) }}
  >
    {image && image.filename && (
      <Image className={styles.background} src={image.filename} alt={name} />
    )}
    {logotype && logotype.filename ? (
      <Image className={styles.logo} src={logotype.filename} alt={name} />
    ) : (
      <Box className={styles.title} as="span">
        {name}
      </Box>
    )}
  </LinkComponent>
)

export default ProducerListCard
