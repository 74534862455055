import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import { Box } from 'reflexbox'
import classnames from 'classnames'

import { DropdownArrow } from 'src/components/UI/Icon/Icon'

import * as styles from './WineSelect.module.scss'

type Item = {
  name: string
  value: string
}

type Props = {
  items: Item[]
  defaultItem: Item
  name: string
  onChange: any
}

const WineSelect = ({ items, defaultItem, name, onChange }: Props) => {
  const customRef = useRef(null)
  const nativeRef = useRef(null)

  const [activeItem, setActiveItem] = useState(defaultItem.value)

  // Native select states (Mobile)
  const [optionWidth, setOptionWidth] = useState(0)

  // Custom select states (Desktop)
  const [menuIsOpen, setMenuIsOpen] = useState()
  const [menuWidth, setMenuWidth] = useState()
  const [isCalculatingWidth, setIsCalculatingWidth] = useState(false)

  useEffect(() => {
    if (!optionWidth) {
      setWidth()
    }

    if (!menuWidth && !isCalculatingWidth) {
      setTimeout(() => {
        const { current: select }: any = customRef ? customRef : {}
        if (select) {
          setIsCalculatingWidth(true)
          // setIsOpen doesn't trigger onOpenMenu, so calling internal method
          select.openMenu()
          setMenuIsOpen(true)
        }
      }, 1)
    }
  }, [optionWidth, menuWidth, isCalculatingWidth])

  const onMenuOpen = () => {
    if (!menuWidth && isCalculatingWidth && customRef) {
      setTimeout(() => {
        const { current: select }: any = customRef ? customRef : {}

        if (select?.menuListRef) {
          const width = select.menuListRef.getBoundingClientRect().width
          setMenuWidth(width)
          setIsCalculatingWidth(false)

          // setting isMenuOpen to undefined and closing menu
          select.onMenuClose()
          setMenuIsOpen(undefined)
        }
      }, 1)
    }
  }

  const setWidth = () => {
    if (nativeRef && nativeRef.current) {
      const width = nativeRef.current.offsetWidth // padding width or arrows

      if (width) {
        setOptionWidth(width)
      }
    }
  }

  const handleChange = (e: any, customWidth = false) => {
    const value = e?.target?.value ? e.target.value : e?.value ? e.value : null

    if (value) {
      setActiveItem(value)
      if (onChange) {
        onChange(value)
      }
      setTimeout(() => {
        if (customWidth) {
          setWidth()
        }
      }, 1)
    }
  }

  const height = '46px'

  const valueStyles = {
    color: '#000',
    background: 'transparent',
    height: height,
    padding: '0px',
    fontStyle: 'italic',
    textOverflow: '',
    textIndent: '1px',
    borderRadius: '0px',
    fontFamily: 'Signifier, Georgia, serif',
    fontSize: '36px',
    lineHeight: '1.2em',
    fontWeight: '400',
    fontFeatureSettings: 'ss02 on',
    outline: 'none',
  }

  const customStyles = {
    placeholder: (defaultStyles: any) => {
      return {
        ...defaultStyles,
        ...valueStyles,
      }
    },
    singleValue: (provided: any, state: any) => ({
      ...provided,
      ...valueStyles,
      padding: '0px',
    }),
    valueContainer: (provided: any) => ({
      padding: '0px',
      height: height,
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: height,
      paddingLeft: '8px',
    }),
    control: (provided: any, state: any) => ({
      ...provided,
      ...valueStyles,
      border: 'none',
      boxShadow: 'none',
      padding: '0px',
      borderBottom: '1px solid black',
      '&:hover': {
        boxShadow: 'none',
        border: 'none',
        borderBottom: '1px solid black',
      },
      display: 'inline-flex ',
    }),

    menu: (provided: any) => ({
      ...provided,
      background: '#FBF8F2',
      boxShadow: '0px 2px 16px rgba(0, 0, 0, 0.1)',
      textAlign: 'left',
      borderRadius: '0px',
      marginTop: `-${height}`,
      padding: '0px',
      ...(menuWidth && { width: menuWidth + 100 }),
      ...(isCalculatingWidth && { height: 0, visibility: 'hidden' }),
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      outline: 'none',
      color: '#000',
      fontFamily: 'Signifier, Georgia, serif',
      fontSize: '28px',
      lineHeight: '1.2em',
      fontWeight: '400',
      fontFeatureSettings: 'ss02 on',
      padding: '8px 16px',
      width: 'auto',
      margin: '0px',
      background: state.isSelected ? '#F3F0E8' : '#FBF8F2',
      '&:hover': {
        background: state.isSelected ? '#E9E5DB' : '#F3F0E8',
      },
    }),
  }

  return (
    <React.Fragment>
      <Box className="hideMobile">
        <Select
          ref={customRef}
          styles={customStyles}
          placeholder={items[0].name}
          options={items.map((item) => {
            return { value: item.value, label: item.name }
          })}
          isSearchable={false}
          onChange={(e) => handleChange(e, false)}
          components={{
            DropdownIndicator: () => <DropdownArrow />,
            IndicatorSeparator: () => null,
          }}
          onMenuOpen={onMenuOpen}
          menuIsOpen={menuIsOpen}
          value={activeItem.value}
          menuShouldScrollIntoView={false}
        />
      </Box>
      <Box className={classnames('hideDesktop', styles.nativeSelect)}>
        <select
          className={styles.select}
          style={{ width: `${optionWidth}px` || 'auto' }}
          onChange={(e) => handleChange(e, true)}
          defaultValue={activeItem.value}
          name={name}
        >
          {items?.length > 0 &&
            items.map((item, index) => {
              return (
                <option key={`option:${item.value}`} value={item.value}>
                  {item.name}
                </option>
              )
            })}
        </select>
        <div className={styles.hidden}>
          <div ref={nativeRef} className={styles.option}>
            {items?.length > 0 &&
              items.find((item: any) => item.value === activeItem).name}
          </div>
        </div>
      </Box>
    </React.Fragment>
  )
}

export default WineSelect
