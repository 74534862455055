import React, { useState } from 'react'
import { Flex, Box } from 'reflexbox'

import {
  getDataSourceEntries,
  getDataSourceEntriesByValues,
} from 'src/helpers/getDataSourceData'
import { Container } from 'src/components/UI/Grid/Grid'
import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import PageNavigation from 'src/components/Navigation/PageNavigation/PageNavigation'
import SubNavigation from 'src/components/Navigation/SubNavigation/SubNavigation'
import FilterSelect from 'src/components/UI/FilterSelect/FilterSelect'
import { getOrderByDataSource } from 'src/helpers/getOrderByDataSource'

import * as styles from './ArticleCategoryHeader.module.scss'
import { Props } from './types'

const ArticleCategoryHeader = ({
  image,
  title,
  preamble,
  topCategories = [],
  tags,
  fullSlug,
  showTags = false,
  activeFilter,
  setActiveFilter,
}: Props): JSX.Element => {
  // Categories
  const topCategory =
    topCategories?.length > 0
      ? topCategories.filter((category: any) => category.isTopCategory)[0]
      : null
  const activeTopCategory =
    fullSlug && topCategories?.length > 0
      ? topCategories.filter(
          (category: any) =>
            category?.slug === fullSlug ||
            category?.slug?.split('/')[1] === fullSlug?.split('/')[1]
        )[0]
      : null

  const sortedTopCategories =
    topCategories?.length > 0
      ? getOrderByDataSource(
          topCategories,
          'article-categories',
          'category',
          [],
          false
        ).sort((a: any, b: any) => b.isTopCategory - a.isTopCategory)
      : []

  // Tags
  const defaultTag = {
    title: 'Se alla',
    slug: '',
    onClick: () => setActiveFilter(''),
  }

  const tagsNavigation =
    tags?.length > 0
      ? getDataSourceEntriesByValues(tags, 'article-tags').map((tag) => {
          return {
            title: tag.name,
            slug: tag.value,
            onClick: () => setActiveFilter(tag.value),
          }
        })
      : []

  const tagsSorted =
    tagsNavigation && tagsNavigation?.length > 0
      ? getOrderByDataSource(tagsNavigation, 'article-tags', 'slug', false)
      : []

  if (tagsSorted?.length > 0) {
    tagsSorted.unshift(defaultTag)
  }

  const activeTag =
    tagsSorted?.length > 0
      ? tagsSorted.filter(
          (tag) => tag.slug === activeFilter || !activeFilter
        )[0]
      : defaultTag

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection={['column', null, null, 'row']}
        flexWrap={['wrap']}
        className={styles.wrapper}
      >
        <Box width={[1, null, null, 1 / 2]} order={[1, null, null, 1]}>
          <Box className={styles.imageWrapper} width={1}>
            {image?.filename && (
              <Image
                preload={true}
                src={image.filename}
                alt={title ? title : ''}
              />
            )}
          </Box>
        </Box>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={[1, null, null, 1 / 2]}
          paddingX={[4, null, null, 6]}
          paddingTop={[14, null, null, 0]}
          paddingBottom={[
            showTags && tagsSorted?.length > 0 ? 14 : 8,
            null,
            null,
            0,
          ]}
          textAlign={['center', null, null, 'left']}
          order={[2, null, null, 1]}
        >
          <Box width={[1, null, null, 4 / 6]}>
            {title && (
              <Box className={styles.title} as="h1">
                {title}
              </Box>
            )}
            {preamble && (
              <Box marginTop={[4, null, null, 4]}>
                <Markdown source={preamble} />
              </Box>
            )}
          </Box>
        </Flex>
        <Box overflow="hidden" width={1} order={[1, null, null, 2]}>
          {sortedTopCategories?.length > 0 && (
            <PageNavigation
              pages={sortedTopCategories}
              rootPage={topCategory}
              rootPageTitle="Se alla"
              activePage={activeTopCategory}
            />
          )}
          {showTags && tagsSorted?.length > 0 && (
            <Box className="hideMobile">
              <SubNavigation
                pages={tagsSorted}
                rootPage={defaultTag}
                activePage={activeTag}
              />
            </Box>
          )}
        </Box>
        {showTags && tagsSorted?.length > 0 && (
          <Container className="hideDesktop" order={[2, null, null, 2]}>
            <Flex alignItems="center">
              <FilterSelect
                name="tags-filter"
                onChange={(e) => {
                  if (e.target.value) {
                    setActiveFilter(e.target.value)
                  } else {
                    setActiveFilter('')
                  }
                }}
                options={tagsSorted.map((tag) => ({
                  value: tag.slug,
                  title: tag.title,
                }))}
              />
            </Flex>
          </Container>
        )}
      </Flex>
    </Flex>
  )
}

export default ArticleCategoryHeader
