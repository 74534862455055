import { getDataSourceEntryName } from 'src/helpers/getDataSourceData'

export const categoryStructure = [
  {
    name: getDataSourceEntryName('red_wine'),
    id: 'red_wine',
    slug: '/shoppen/rott-vin',
  },
  {
    name: getDataSourceEntryName('white_wine'),
    id: 'white_wine',
    slug: '/shoppen/vitt-vin',
  },
  {
    name: getDataSourceEntryName('sparkling_wine'),
    id: 'sparkling_wine',
    slug: '/shoppen/mousserande-vin',
  },
  {
    name: getDataSourceEntryName('rose'),
    id: 'rose',
    slug: '/shoppen/rosevin',
  },
  {
    name: getDataSourceEntryName('strong_wine'),
    id: 'strong_wine',
    slug: '/shoppen/starkvin',
  },
  {
    name: getDataSourceEntryName('dessert_wine'),
    id: 'dessert_wine',
    slug: '/shoppen/dessertvin',
  },
  {
    name: getDataSourceEntryName('other_drinks'),
    id: 'other_drinks',
    slug: '/shoppen/ovriga-drycker/',
    subItems: [
      {
        name: getDataSourceEntryName('alcohol-free'),
        id: 'alcohol-free',
        slug: '/shoppen/ovriga-drycker/alkoholfritt',
      },
      {
        name: getDataSourceEntryName('beer'),
        id: 'beer',
        slug: '/shoppen/ovriga-drycker/ol',
      },
      {
        name: getDataSourceEntryName('spirits'),
        id: 'spirits',
        slug: '/shoppen/ovriga-drycker/sprit',
      },
      {
        name: getDataSourceEntryName('sherry'),
        id: 'spirits',
        slug: '/shoppen/ovriga-drycker/sherry',
      },
      {
        name: getDataSourceEntryName('port'),
        id: 'spirits',
        slug: '/shoppen/ovriga-drycker/portvin',
      },
    ],
  },
]

export const getCategoryStructure = (categories: string[]) => {
  let cat: any = {}
  const data = { ...categoryStructure }
  if (categories && categories.length > 0) {
    Object.values(data).forEach((values: any) => {
      if (categories.includes(values.id)) {
        cat = { ...values }
      }
    })
  }
  if (cat && cat.subItems) {
    const subItems = { ...cat.subItems }
    cat.subItems = Object.values(subItems).filter((item: any) => {
      return categories.includes(item.id)
    })
  }
  return cat
}

export const getCategoryName = (categories: string[]) => {
  let category = ''
  const catStructure = getCategoryStructure(categories)
  if (catStructure && catStructure.id) {
    if (catStructure.subItems && catStructure.subItems.length > 0) {
      category = catStructure.subItems[0].name
    } else {
      category = catStructure.name
    }
  }
  return category
}
