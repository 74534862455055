import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { Container, Row, Column } from 'src/components/UI/Grid/Grid'

import * as styles from './ArticleGrid.module.scss'
import { Props } from './types'
import ArticleItem from './ArticleItem/ArticleItem'

const ArticleGrid = ({ articles, showTags }: Props): JSX.Element => {
  const mockArticles = articles

  const ratio6_7 = (7 / 6) * 100 + '%'
  const ratio4_5 = (5 / 4) * 100 + '%'
  const ratio16_9 = (9 / 16) * 100 + '%'
  const ratio6_8 = (8 / 6) * 100 + '%'
  const ratios = [
    ratio6_7,
    ratio4_5,
    ratio6_7,
    ratio16_9,
    ratio6_8,
    ratio4_5,
    ratio6_7,
    ratio4_5,
    ratio6_7,
    ratio16_9,
    ratio6_7,
    ratio4_5,
    ratio6_7,
  ]
  let ratioIndex = 0

  return mockArticles?.length > 0 ? (
    <Container paddingX={[0, null, null, 14]} paddingTop={[6, null, null, 12]}>
      <Row
        flexDirection="row"
        justifyContent="flex-start"
        marginX={[0, null, null, '-44px']}
      >
        {mockArticles.map((article, index) => {
          const ratio = ratios[ratioIndex]
          const isLargeMobile =
            (ratioIndex === 3 || ratioIndex === 9) && ratioIndex > 0
          const isLargeDesktop =
            (ratioIndex === 3 || ratioIndex === 9) && ratioIndex > 0
          ratioIndex++
          if (ratioIndex > 13) {
            ratioIndex = 0
          }
          return (
            <Column
              key={article.id}
              paddingX={[isLargeMobile ? 0 : 4, null, null, 11]}
              width={[
                isLargeMobile ? 1 : 1 / 2,
                null,
                null,
                isLargeDesktop ? 8 / 12 : 4 / 12,
              ]}
              order={[isLargeMobile ? index + 2 : index, null, null, index]}
            >
              <Box marginBottom={[10, null, null, 16]} width={1}>
                <ArticleItem
                  article={article}
                  ratio={ratio}
                  showTags={showTags}
                  showPreambleMobile={isLargeMobile}
                  showPreamble={isLargeDesktop}
                />
              </Box>
            </Column>
          )
        })}
      </Row>
    </Container>
  ) : (
    <Box />
  )
}

export default ArticleGrid
