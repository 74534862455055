import React from 'react'
import { Box, Flex } from 'reflexbox'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { getDataSourceEntryName } from 'src/helpers/getDataSourceData'

import Markdown from '../Markdown/Markdown'
import Image from '../UI/Image/Image'
import * as styles from './ArticleCard.module.scss'
import { Props } from './types'
import StoryblokVideo, {
  getMediaType,
} from 'src/storyblok/Components/StoryblokVideo/StoryblokVideo'

const ArticleCard = ({
  article,
  showLabel,
  showPreamble,
  ratio,
}: Props): JSX.Element => {
  const { slug, component } = article
  const {
    image,
    category,
    country,
    region,
    preamble,
    header_image,
    description,
  } = article
  const mediaType = image && getMediaType(image.filename)
  let { title } = article

  const isArticle =
    component !== 'page_country' &&
    component !== 'page_producer' &&
    component !== 'page_region'

  let categoryName = category ? getDataSourceEntryName(category) : ''

  if (!isArticle) {
    switch (component) {
      case 'page_country':
        categoryName = 'Land'
        title = getDataSourceEntryName(country)
        break
      case 'page_producer':
        categoryName = 'Producent'
        break
      case 'page_region':
        categoryName = 'Region'
        title = getDataSourceEntryName(region)
        break
      default:
        break
    }
  }

  return isArticle ? (
    <Flex className={styles.wrapper} flexDirection="column" width={1}>
      {showLabel && categoryName && (
        <Box padding={[4]} as="span" className={styles.label}>
          {categoryName}
        </Box>
      )}
      <LinkComponent to={`/${slug}`}>
        <Box className={styles.imageWrapper} marginBottom={[6]}>
          <Box width={1} paddingTop={ratio} />
          {mediaType === 'video' ? (
            <StoryblokVideo src={image.filename} fill autoplay />
          ) : (
            image &&
            image?.filename && (
              <Image
                src={image.filename}
                alt={title}
                sizes={{
                  mobile: '50vw',
                  tablet: '33vw',
                  desktop: '33vw',
                }}
              />
            )
          )}
        </Box>
        <Box className={styles.title} as="span" width={[1, null, null, 3 / 4]}>
          {title}
        </Box>
        {showPreamble && preamble && (
          <Box
            marginTop={2}
            className={styles.preamble}
            width={[1, null, null, 3 / 4]}
          >
            <Markdown source={preamble} />
          </Box>
        )}
      </LinkComponent>
    </Flex>
  ) : (
    <Flex className={styles.wrapper} flexDirection="column" width={1}>
      {showLabel && categoryName && (
        <Box padding={[4]} as="span" className={styles.label}>
          {categoryName}
        </Box>
      )}
      <LinkComponent to={`/${slug}`}>
        <Box className={styles.imageWrapper} marginBottom={[6]}>
          <Box width={1} paddingTop={ratio} />
          {header_image && header_image?.filename && (
            <Image
              src={header_image.filename}
              alt={title}
              sizes={{
                mobile: '50vw',
                tablet: '33vw',
                desktop: '33vw',
              }}
            />
          )}
        </Box>
        <Box className={styles.title} as="span" width={[1, null, null, 3 / 4]}>
          {title}
        </Box>
        {showPreamble && description && (
          <Box
            marginTop={2}
            className={styles.preamble}
            width={[1, null, null, 3 / 4]}
          >
            <Box as="p">{description}</Box>
          </Box>
        )}
      </LinkComponent>
    </Flex>
  )
}

export default ArticleCard
