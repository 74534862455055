import React from 'react'
import { Flex, Box } from 'reflexbox'
import { useRecoilState } from 'recoil'

import { ourFavoritesState } from 'src/recoil/atoms'
import ProductHighlight from 'src/components/ProductHighlight/ProductHighlight'
import Components from 'src/storyblok/Components'
import { Product } from 'src/globalTypes/types'
import ProductCard from 'src/components//ProductCard/ProductCard'
import { Row, Column } from 'src/components/UI/Grid/Grid'

import * as styles from './ProductGrid.module.scss'
import { Props, ProductColumnProps } from './types'

const ProductColumn = ({
  gridType,
  gridTypeMobile,
  orderCount,
  product,
  isFavorite,
}: ProductColumnProps) => (
  <Column
    width={
      gridType === 'condensed'
        ? [
            gridTypeMobile === 'full' || isFavorite ? 6 / 6 : 3 / 6,
            null,
            null,
            isFavorite ? 6 / 9 : 3 / 9,
          ]
        : [
            gridTypeMobile === 'full' || isFavorite ? 6 / 6 : 3 / 6,
            null,
            null,
            isFavorite ? 6 / 12 : 3 / 12,
          ]
    }
    order={orderCount ? [orderCount, null, null, orderCount] : 1}
  >
    <Flex
      className={isFavorite ? styles.productHighlight : ''}
      justifyContent={isFavorite ? 'center' : ''}
      flexDirection="column"
      marginBottom={[6, null, null, 6]}
    >
      {isFavorite ? (
        <ProductHighlight product={product} label="Vår favorit" type="large" />
      ) : (
        <ProductCard {...product} />
      )}
    </Flex>
  </Column>
)

const ProductGrid = ({
  products,
  content,
  gridType,
  gridTypeMobile,
}: Props): JSX.Element => {
  const [ourFavorites] = useRecoilState(ourFavoritesState)
  const totalContent = content?.length ? content.length : 0

  let contentCount = -1
  let orderCount = 0

  // Use custom grid if content is available
  return totalContent > 0 ? (
    <Row flexDirection="row">
      {products?.length > 0 &&
        products.map((product: Product, index: number) => {
          const parsedProduct = product?.id ? product : {}
          const isFavorite =
            ourFavorites.filter(
              (favorite: string) => favorite === parsedProduct.id
            ).length > 0
          const useContent = index % 8 === 0 && index > 0

          if (useContent) {
            contentCount++
            orderCount++

            if (isFavorite) {
              contentCount++
              orderCount++
            }
          }

          orderCount++
          return useContent && contentCount < totalContent ? (
            <React.Fragment key={parsedProduct.id + index}>
              <Column
                width={[1, null, null, 1]}
                order={
                  gridType === 'condensed'
                    ? [orderCount - 8, null, null, orderCount - 4]
                    : [orderCount - 6, null, null, orderCount - 1]
                }
              >
                {content.map((blok: any, index: any) => {
                  return index === contentCount ? (
                    <Box
                      key={`flex-${blok._uid}`}
                      marginBottom={[6, null, null, 6]}
                      marginRight={['-16px', null, null, '-24px']}
                      marginLeft={
                        gridType === 'condensed'
                          ? ['-16px', null, null, 0]
                          : ['-16px', null, null, '-24px']
                      }
                    >
                      {React.createElement(Components(blok.component), {
                        key: blok._uid,
                        blok: blok,
                      })}
                    </Box>
                  ) : null
                })}
              </Column>
              <ProductColumn
                gridType={gridType}
                gridTypeMobile={gridTypeMobile}
                orderCount={orderCount}
                product={parsedProduct}
                isFavorite={isFavorite}
              />
            </React.Fragment>
          ) : (
            <ProductColumn
              key={parsedProduct.id + index}
              gridType={gridType}
              gridTypeMobile={gridTypeMobile}
              orderCount={orderCount}
              product={parsedProduct}
              isFavorite={isFavorite}
            />
          )
        })}
    </Row>
  ) : (
    <Row flexDirection="row">
      {products?.length > 0 &&
        products.map((product: Product, index: number) => {
          const parsedProduct = product?.id ? product : {}
          const isFavorite =
            ourFavorites.filter(
              (favorite: string) => favorite === parsedProduct.id
            ).length > 0
          return (
            <ProductColumn
              key={parsedProduct.id + index}
              gridType={gridType}
              gridTypeMobile={gridTypeMobile}
              product={parsedProduct}
              isFavorite={isFavorite}
            />
          )
        })}
    </Row>
  )
}

export default ProductGrid
