import React, { useState } from 'react'
import { Box, Flex } from 'reflexbox'
import YouTube from 'react-youtube'
import classnames from 'classnames'
import Vimeo from '@u-wave/react-vimeo'

import { PlayIcon } from 'src/components/UI/Icon/Icon'
import Image from 'src/components/UI/Image/Image'

import * as styles from './VimeoVideo.module.scss'
import { Props } from './types'

const VimeoVideo = ({ source, image, className }: Props) => {
  const [videoPlaying, setVideoPlaying] = useState(false)
  const [videoActive, setVideoActive] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(image ? false : true)

  const onClick = () => {
    setVideoPlaying(!videoPlaying)
    setVideoActive(true)
  }

  return source ? (
    <Box className={classnames(styles.videoWrapper, className)} width={1}>
      {!videoActive && image && (
        <Flex
          className={styles.poster}
          alignItems="center"
          justifyContent="center"
          onClick={onClick}
        >
          <Flex
            className={styles.playButton}
            alignItems="center"
            justifyContent="center"
          >
            <PlayIcon />
          </Flex>

          <Image
            src={image}
            sizes={'100vw'}
            alt={image.title}
            onImageLoaded={() => {
              setTimeout(() => {
                setImageLoaded(true)
              }, 800) // Set to same animation time as .o-image in global.scss
            }}
          />
        </Flex>
      )}
      {imageLoaded && (
        <Vimeo
          controls={true}
          video={source}
          muted={false}
          loop={false}
          width={'100%'}
          showByline={false}
          showPortrait={false}
          showTitle={false}
          background={false}
          // onReady={onVideoReady}
          paused={!videoPlaying}
        />
      )}
    </Box>
  ) : null
}

export default VimeoVideo
