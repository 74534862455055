import React from 'react'
import { Box, Flex } from 'reflexbox'

import ButtonText from 'src/components/UI/Button/ButtonText/ButtonText'
import Markdown from 'src/components/Markdown/Markdown'

import LinkComponent from '../LinkComponent/LinkComponent'
import { Container } from '../UI/Grid/Grid'
import Image from '../UI/Image/Image'
import * as styles from './ListHeader.module.scss'
import { Props } from './types'

const ListHeader = ({
  label,
  title,
  image,
  description,
  slug,
  linkText,
  large,
}: Props) => (
  <Flex flexDirection={['column', null, null, 'row']} className={styles.header}>
    <Box
      className={
        large
          ? [styles.imageSection, styles.large].join(' ')
          : styles.imageSection
      }
      width={[1, null, null, 1 / 2]}
    >
      {image && image.filename && <Image src={image.filename} alt={title} />}
    </Box>
    <Flex
      paddingY={[10]}
      justifyContent="flex-start"
      alignItems="center"
      width={[1, null, null, 1 / 2]}
    >
      <Box
        paddingLeft={[0, null, null, 18]}
        textAlign={[large ? 'left' : 'center', null, null, 'left']}
        width={[1, null, null, 5 / 6]}
      >
        <Container>
          <Flex flexDirection="column" alignItems="flex-start">
            {label && (
              <Box marginBottom={1} className={styles.label} as="span">
                {label}
              </Box>
            )}
            <Box marginBottom={[4]} as="h1">
              {title}
            </Box>
            {description && (
              <Box>
                <Markdown source={description} />
              </Box>
            )}
            {slug && linkText && (
              <Box marginTop={[5]}>
                <ButtonText to={slug}>{linkText}</ButtonText>
              </Box>
            )}
          </Flex>
        </Container>
      </Box>
    </Flex>
  </Flex>
)

export default ListHeader
