import React from 'react'
import { Box, Flex } from 'reflexbox'
import { SplideSlide } from '@splidejs/react-splide'
import classnames from 'classnames'

import { parseStoryblokArticleToAlgolia } from 'src/algolia/clienthelpers'
import ArticleCard from 'src/components/ArticleCard/ArticleCard'
import Markdown from 'src/components/Markdown/Markdown'
import Carousel from 'src/components/Carousel/Carousel'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import { GoesWellWith } from 'src/components/Product/GoesWellWith/GoesWellWith'

import { Props } from './types'
import * as styles from './Pairings.module.scss'

export const Pairings = ({
  articles,
  title,
  text,
  goesWellWith,
}: Props): JSX.Element => {
  const ratios = ['110%', '129.9%', '100%']

  let ratioIndex = 0
  return (
    <Flex
      className={styles.wrapper}
      alignItems="center"
      flexDirection={['column', null, null, 'row']}
    >
      <Container
        className={classnames(styles.wrapper)}
        as="section"
        paddingTop={[8, null, null, 20]}
        paddingBottom={['30px', null, null, 25]}
        flexDirection="column"
        width={[1, null, null, 5 / 12]}
      >
        <Row alignItems="center">
          <Column width={[1]}>
            <Flex width={1} flexDirection="column" alignItems="center">
              <Box width={[1, null, null, 8 / 10]}>
                <Box className={styles.title} as="h3">
                  {title}
                </Box>
                {text && (
                  <Box marginTop={[6, null, null, 6]}>
                    <Markdown source={text} />
                  </Box>
                )}
                {goesWellWith?.length > 0 && (
                  <Box marginTop={[7, null, null, 7]}>
                    <GoesWellWith goesWellWith={goesWellWith} />
                  </Box>
                )}
              </Box>
            </Flex>
          </Column>
        </Row>
      </Container>
      <Box
        paddingTop={[0, null, null, '64px']}
        paddingBottom={['60px']}
        className={classnames(styles.wrapper)}
        width={1}
      >
        <Box width={1}>
          <Column width={[1, null, null, 6 / 12]}>
            <Box
              marginLeft={[5, null, null, `${(1 / 12) * 100}%`]}
              width={[4 / 6, null, null, 8 / 12]}
            >
              <Carousel
                showControls={articles?.length > 2 ? true : false}
                classNameControls={styles.controls}
              >
                {articles?.length > 0 &&
                  articles.map((article: any, index: number) => {
                    const ratio = ratios[ratioIndex]
                    ratioIndex++
                    if (ratioIndex > 2) {
                      ratioIndex = 0
                    }
                    const parsedArticle =
                      parseStoryblokArticleToAlgolia(article)
                    return parsedArticle?.id ? (
                      <SplideSlide key={`carousel-${index}`}>
                        <ArticleCard
                          showPreamble={false}
                          showLabel={false}
                          ratio={ratio}
                          article={parsedArticle}
                        />
                      </SplideSlide>
                    ) : null
                  })}
              </Carousel>
            </Box>
          </Column>
        </Box>
      </Box>
    </Flex>
  )
}
