import React, { useRef, useEffect } from 'react'
import classnames from 'classnames'
import { Flex, Box } from 'reflexbox'

import { ChevronDownIcon } from 'src/components/UI/Icon/Icon'

import * as styles from './SortSelect.module.scss'
import { Props } from './types'

const SortSelect = ({
  name,
  id,
  value,
  onChange,
  className,
  options,
}: Props): JSX.Element => {
  const labelRef = useRef(null)
  const selectRef = useRef(null)

  useEffect(() => {
    const currentLabelRef = labelRef?.current ? labelRef.current : null
    const currentSelectRef = selectRef?.current ? selectRef.current : null

    if (currentLabelRef && currentSelectRef) {
      const labelWidth = currentLabelRef.offsetWidth

      currentSelectRef.style.paddingLeft = labelWidth + 4 + 'px'
    }
  }, [value])

  return (
    <Flex alignItems="center" className={classnames(styles.sort, className)}>
      <Box
        ref={labelRef}
        className={styles.sortLabel}
        htmlFor={name}
        as="label"
      >
        Sortera på:
      </Box>
      <select
        onChange={onChange}
        ref={selectRef}
        className={styles.sortSelect}
        name={name}
        id={id}
        defaultValue={value}
      >
        {options?.length > 0 &&
          options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.title}
            </option>
          ))}
      </select>
      <Box className={styles.sortIcon} as="span">
        <ChevronDownIcon />{' '}
      </Box>
    </Flex>
  )
}

export default SortSelect
