import React from 'react'
import SbEditable from 'storyblok-react'
import { Flex, Box } from 'reflexbox'

import ProductHighlight from 'src/components/ProductHighlight/ProductHighlight'
import ArticleHighlight from 'src/components/ArticleHighlight/ArticleHighlight'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import {
  parseStoryblokProductToAlgolia,
  parseStoryblokArticleToAlgolia,
} from 'src/algolia/clienthelpers'

import { Props } from './types'
import * as styles from './ProductAndArticle.module.scss'

const ProductAndArticle = ({ blok }: Props): JSX.Element => {
  const { product_label, product, article_label, article } = blok

  return (
    <SbEditable content={blok}>
      <Container
        className={styles.wrapper}
        as="section"
        paddingTop={[5, null, null, 25]}
        paddingBottom={[15, null, null, 25]}
      >
        <Row alignItems="center">
          <Column width={[1, null, null, 1 / 2]}>
            {product && (
              <Box marginBottom={[13, null, null, 0]}>
                <ProductHighlight
                  product={parseStoryblokProductToAlgolia(product)}
                  label={product_label}
                />
              </Box>
            )}
          </Column>
          <Column width={[1, null, null, 1 / 2]}>
            <Row
              alignItems={['center', null, null, 'flex-start']}
              justifyContent="center"
            >
              <Column width={[4 / 6, null, null, 4 / 6]}>
                {article && (
                  <Box paddingX={[0, null, null, (1 / 12) * 100 + '%']}>
                    <ArticleHighlight
                      article={parseStoryblokArticleToAlgolia(article)}
                      label={article_label}
                    />
                  </Box>
                )}
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default ProductAndArticle
