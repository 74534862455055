import React from 'react'

export const getFlag = (country: string): JSX.Element => {
  switch (country) {
    case 'Argentina':
      return <img src="/flags/argentina.svg" alt="Argentina" />
    case 'Österrike':
      return <img src="/flags/austria.svg" alt="Austria" />
    case 'Australien':
      return <img src="/flags/australia.svg" alt="australia" />
    case 'Västindien':
      return <img src="/flags/caribbean.svg" alt="Caribbean" />
    case 'Chile':
      return <img src="/flags/chile.svg" alt="Chile" />
    case 'Frankrike':
      return <img src="/flags/france.svg" alt="France" />
    case 'Grekland':
      return <img src="/flags/greece.svg" alt="Greece" />
    case 'Marocco':
      return <img src="/flags/morocco.svg" alt="Morocco" />
    case 'Tyskland':
      return <img src="/flags/germany.svg" alt="Germany" />
    case 'Italien':
      return <img src="/flags/italy.svg" alt="Italy" />
    case 'Nya Zeeland':
      return <img src="/flags/newzealand.svg" alt="New Zealand" />
    case 'Portugal':
      return <img src="/flags/portugal.svg" alt="Portugal" />
    case 'Sydafrika':
      return <img src="/flags/southafrica.svg" alt="South Africa" />
    case 'Spanien':
      return <img src="/flags/spain.svg" alt="Spain" />
    case 'USA':
      return <img src="/flags/usa.svg" alt="USA" />
    default:
      return <div />
  }
}
