import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import ListHeader from 'src/components/ListHeader/ListHeader'
import { getDataSourceEntryName } from 'src/helpers/getDataSourceData'
import Components from 'src/storyblok/Components'

import { Props } from './types'
// import * as styles from './PageProducer.module.scss'

const PageProducer = ({ blok, full_slug }: Props) => {
  const { header_image, title, description, producer, content, seo_data } = blok

  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    title: title || '',
    description: description || '',
    image: header_image?.filename || '',
  })
  return (
    <SbEditable content={blok}>
      <Flex width={1} flexDirection="column">
        <SEO
          url={full_slug}
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
        />
        <ListHeader
          label={getDataSourceEntryName(producer)}
          title={title}
          large
          image={header_image}
          description={description}
          slug={`/shoppen/#producer=${producer}`}
          linkText={`Upptäck alla viner från ${getDataSourceEntryName(
            producer
          )}`}
        />
      </Flex>
      {content?.length > 0 &&
        content.map((blok: any) => (
          <Box key={blok._uid}>
            {React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })}
          </Box>
        ))}
    </SbEditable>
  )
}

export default PageProducer
