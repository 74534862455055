import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Markdown from 'src/components/Markdown/Markdown'
import { Container } from 'src/components/UI/Grid/Grid'
import Image from 'src/components/UI/Image/Image'
import { getColor } from 'src/helpers/getColor'

import * as styles from './ImageAndText.module.scss'
import { Props } from './types'
import StoryblokVideo, {
  getMediaType,
} from 'src/storyblok/Components/StoryblokVideo/StoryblokVideo'

const ImageAndText = ({ blok }: Props): JSX.Element => {
  const {
    image,
    text,
    image_position,

    mobile_image_position,
    background,
  } = blok

  const bg = background && background !== 'none' ? getColor(background) : null
  const mediaType = image ? getMediaType(image.filename) : ''
  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
      >
        <Box
          order={[
            mobile_image_position === 'bottom' ? 1 : 0,
            null,
            null,
            image_position === 'left' ? 0 : 1,
          ]}
          width={[1, null, null, 1 / 2]}
        >
          {mediaType === 'video' ? (
            <div className={styles.video}>
              <StoryblokVideo autoplay fill src={image.filename} />
            </div>
          ) : (
            image &&
            image.filename && (
              <Image src={image.filename} alt={image.filename} />
            )
          )}
        </Box>
        <Flex
          order={[
            mobile_image_position === 'bottom' ? 0 : 1,
            null,
            null,
            image_position === 'left' ? 1 : 0,
          ]}
          alignItems="center"
          justifyContent="center"
          paddingY={[10, null, null, 8]}
          width={[1, null, null, 1 / 2]}
          bg={bg}
        >
          <Box width={[1, null, 10 / 12, 4.5 / 6]}>
            <Container>
              <Markdown source={text} />
            </Container>
          </Box>
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default ImageAndText
