// extracted by mini-css-extract-plugin
export var col = "HeroGrid-module--col--mse3w";
export var col1 = "HeroGrid-module--col1--ZSNMd";
export var col2 = "HeroGrid-module--col2--n4816";
export var col3 = "HeroGrid-module--col3--q09Ic";
export var col4 = "HeroGrid-module--col4--0KH+Y";
export var container = "HeroGrid-module--container--gshg3";
export var flex = "HeroGrid-module--flex--xX5tI";
export var grid = "HeroGrid-module--grid--8aNjW";
export var large = "HeroGrid-module--large--UY1LZ";
export var slider = "HeroGrid-module--slider--stU7Q";
export var wrapper = "HeroGrid-module--wrapper--jayEg";