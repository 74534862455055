import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import YouTubeVideo from 'src/components/YouTubeVideo/YouTubeVideo'
import VimeoVideo from 'src/components/VimeoVideo/VimeoVideo'
import Markdown from 'src/components/Markdown/Markdown'
import { Container } from 'src/components/UI/Grid/Grid'
import Image from 'src/components/UI/Image/Image'

import * as styles from './VideoAndText.module.scss'
import { Props } from './types'

const VideoAndText = ({ blok }: Props): JSX.Element => {
  const {
    cover_image,
    text,
    video_service,
    video_source,
    video_position,
  } = blok
  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
      >
        <Box
          order={video_position === 'left' ? 0 : 1}
          width={[1, null, null, 1 / 2]}
          className={styles.videoWrapper}
        >
          {video_service === 'youtube' && video_source && (
            <YouTubeVideo
              className={styles.video}
              id={video_source}
              image={
                cover_image && cover_image.filename ? cover_image.filename : ''
              }
            />
          )}
          {video_service === 'vimeo' && video_source && (
            <VimeoVideo
              className={styles.video}
              source={video_source}
              image={
                cover_image && cover_image.filename ? cover_image.filename : ''
              }
            />
          )}
        </Box>
        <Flex
          order={video_position === 'left' ? 1 : 0}
          alignItems="center"
          justifyContent="center"
          width={[1, null, null, 1 / 2]}
        >
          <Box width={[1, null, 10 / 12, 4.5 / 6]}>
            <Container paddingY={[6, null, null, 24]}>
              <Markdown source={text} />
            </Container>
          </Box>
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default VideoAndText
