import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import ButtonText from 'src/components/UI/Button/ButtonText/ButtonText'

import { Props } from './types'
import * as styles from './QuoteAndText.module.scss'

const QuoteAndText = ({ blok }: Props): JSX.Element => {
  const { label, title, quote, text, image, link } = blok

  const { url, title: linkTitle } =
    link && link?.length > 0 ? getLinkAttributes(link[0]) : {}
  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
        width={1}
        as="section"
      >
        <Flex className={styles.imageWrapper} width={[1, null, null, 1 / 2]}>
          <Flex
            flexDirection="column"
            justifyContent="center"
            paddingX={[4, null, null, 20]}
            className={styles.content}
          >
            {label && (
              <Box marginBottom={[2]} className={styles.label} as="span">
                {label}
              </Box>
            )}
            {quote && (
              <Box className={styles.quote} as="p">
                {quote}
              </Box>
            )}
          </Flex>
          {image?.filename && (
            <Image
              className={styles.image}
              src={image.filename}
              alt={label ? label : title}
            />
          )}
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          paddingX={[4, null, null, 20]}
          paddingY={[10, null, null, 30]}
          width={[1, null, null, 1 / 2]}
          className={styles.content}
        >
          {label && (
            <Box
              marginBottom={[2]}
              className={classnames(styles.label, styles.labelPrint)}
              as="span"
            >
              {label}
            </Box>
          )}
          {text && (
            <Box marginBottom={[2, null, null, 2]}>
              <Markdown source={text} />
            </Box>
          )}
          {url && linkTitle && (
            <ButtonText className={styles.link} to={url}>
              {linkTitle}
            </ButtonText>
          )}
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default QuoteAndText
