import React from 'react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'

import { getDataSourceEntries } from 'src/helpers/getDataSourceData'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import Markdown from 'src/components/Markdown/Markdown'
import Highlight from 'src/storyblok/Components/Highlight/Highlight'
import PageNavigation from 'src/components/Navigation/PageNavigation/PageNavigation'
import SubNavigation from 'src/components/Navigation/SubNavigation/SubNavigation'
import { getOrderByDataSource } from 'src/helpers/getOrderByDataSource'

import * as styles from './CategoryHeader.module.scss'
import { Props } from './types'

const CategoryHeader = ({
  title,
  fullSlug,
  preamble,
  theme,
  highlights,
  topCategories,
  categories,
  totalProducts,
}: Props): JSX.Element => {
  const rootCategories =
    categories?.length > 0
      ? categories.filter((category: any) => category.isRootCategory)
      : []

  // Get top and active category
  const topCategory =
    topCategories?.length > 0
      ? topCategories.filter((category: any) => category.isTopCategory)[0]
      : null
  const activeTopCategory =
    fullSlug && topCategories?.length > 0
      ? topCategories.filter(
          (category: any) =>
            category?.slug === fullSlug ||
            category?.slug?.split('/')[1] === fullSlug?.split('/')[1]
        )[0]
      : null
  const rootCategory = rootCategories?.length > 0 ? rootCategories[0] : null
  const activeCategory =
    fullSlug && categories?.length > 0
      ? categories.filter((category: any) => category.slug === fullSlug)[0]
      : null

  // Set breadcrumbs from category and filter out empty items
  const breadcrumbs = [
    topCategory?.title && topCategory?.slug
      ? { title: topCategory.title, slug: topCategory.slug }
      : null,
    rootCategory?.title && rootCategory?.slug
      ? { title: rootCategory.title, slug: rootCategory.slug }
      : null,
    activeCategory?.title &&
    activeCategory?.slug &&
    rootCategory?.title !== activeCategory?.title
      ? { title: activeCategory.title, slug: activeCategory.slug }
      : null,
  ].filter((breadcrumb: any) => breadcrumb?.title)

  // Sort by data sources order
  const sortedTopCategories =
    topCategories?.length > 0
      ? getOrderByDataSource(
          topCategories,
          'categories',
          'category',
          [],
          false
        ).sort((a: any, b: any) => b.isTopCategory - a.isTopCategory)
      : []
  const sortedCategories =
    categories?.length > 0
      ? getOrderByDataSource(
          categories,
          'categories',
          'category',
          [],
          false
        ).sort((a: any, b: any) => b.isRootCategory - a.isRootCategory)
      : []

  // UI
  const wrapperClassName = classnames(
    styles.wrapper,
    theme === 'rose' ? styles.themeRose : ''
  )
  return (
    <Container as="section" className={wrapperClassName}>
      <Row justifyContent="space-between">
        <Column width={[1, null, null, 4 / 12]} order={1}>
          <Flex
            paddingTop={[15, null, null, 18]}
            paddingBottom={[7, null, null, 10]}
            flexDirection="column"
            justifyContent="center"
            height="100%"
          >
            {breadcrumbs && breadcrumbs.length > 0 && (
              <Flex className={styles.breadcrumbs}>
                {breadcrumbs.map((breadcrumb, index) => {
                  return breadcrumb?.title && breadcrumb?.slug ? (
                    <React.Fragment key={`breadcrumb-${index}`}>
                      <LinkComponent to={breadcrumb.slug}>
                        {breadcrumb.title}
                      </LinkComponent>
                      {index < breadcrumbs.length - 1 && (
                        <Box className={styles.breadcrumbDivider}>/</Box>
                      )}
                    </React.Fragment>
                  ) : null
                })}
              </Flex>
            )}
            <Flex marginBottom={[3, null, null, 3]} alignItems="flex-end">
              {title && (
                <Box className={styles.title} as="h1">
                  {title}
                </Box>
              )}

              {/* <Box
                marginLeft={[3, null, null, 3]}
                marginBottom={[1, null, null, 1]}
                className={styles.totalProducts}
              >
                {totalProducts
                  ? `${totalProducts} ${
                      totalProducts === 1 ? `produkt` : `produkter`
                    }`
                  : `Inga produkter hittades`}
              </Box> */}
            </Flex>
            {preamble && (
              <Markdown className={styles.preamble} source={preamble} />
            )}
          </Flex>
        </Column>
        <Column width={[1, null, null, 7 / 12]} order={[2, null, null, 1]}>
          <Box
            className={styles.highlightsWrapper}
            marginX={['-16px', null, null, '-24px']}
          >
            <Box className={styles.highlights} paddingTop={[4, null, null, 0]}>
              <Flex width={['130%', null, null, 1]}>
                {highlights &&
                  highlights.length > 0 &&
                  highlights.map((highlight: any, index: number) => {
                    return (
                      <Box
                        key={highlight._uid}
                        width={1}
                        marginLeft={[index === 0 ? 4 : 2, null, null, 0]}
                        marginRight={[
                          index === highlights.length - 1 ? 5 : 0,
                          null,
                          null,
                          0,
                        ]}
                      >
                        <Highlight
                          blok={highlight}
                          preload={index === 0}
                          type="fixed"
                          size="small"
                        />
                      </Box>
                    )
                  })}
              </Flex>
            </Box>
          </Box>
        </Column>
        <Column width={1} order={[1, null, null, 2]}>
          {sortedTopCategories?.length > 0 && (
            <Box marginX={['-16px', null, null, '-24px']}>
              <PageNavigation
                pages={sortedTopCategories}
                rootPage={topCategory}
                rootPageTitle="Se alla"
                activePage={activeTopCategory}
              />
            </Box>
          )}
          {sortedCategories?.length > 0 && (
            <Box marginX={['-16px', null, null, '-24px']}>
              <SubNavigation
                pages={sortedCategories}
                rootPage={rootCategory}
                rootPageTitle="Visa alla"
                activePage={activeCategory}
              />
            </Box>
          )}
        </Column>
      </Row>
    </Container>
  )
}

export default CategoryHeader
