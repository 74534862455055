import React from 'react'
import { Flex } from 'reflexbox'

import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import Components from 'src/storyblok/Components'
import * as styles from './PagePress.module.scss'
import { Props } from './types'

const PagePress = ({ blok, full_slug }: Props) => {
  const { body, seo_data } = blok

  const { seo_title, seo_description, seo_image } = getSeo(seo_data)

  return (
    <Flex className={styles.wrapper} width={1} flexDirection="column">
      <SEO
        url={full_slug}
        title={seo_title}
        metaDescription={seo_description}
        image={seo_image}
      />
      {body?.length > 0 &&
        body.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}
    </Flex>
  )
}

export default PagePress
