import React from 'react'
import classnames from 'classnames'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import ButtonGhost from 'src/components/UI/Button/ButtonGhost/ButtonGhost'
import { SbLink } from 'src/storyblok/types/storyblokTypes'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './Banner.module.scss'
import { Props } from './types'

const Banner = ({ blok }: Props): JSX.Element => {
  const { title, text, link, theme } = blok

  const { url, title: linkTitle } =
    link && link?.length > 0 ? getLinkAttributes(link[0]) : {}

  const wrapperStyles = classnames(
    styles.wrapper,
    theme === 'rose'
      ? styles.roseTheme
      : theme === 'green'
      ? styles.greenTheme
      : ''
  )
  return (
    <SbEditable content={blok}>
      <Container
        className={wrapperStyles}
        paddingTop={[40, null, null, 20]}
        paddingBottom={[40, null, null, 20]}
        paddingX={[3, null, null, 6]}
      >
        <Row flexDirection="row" justifyContent="center" alignItems="center">
          <Column width={[1, null, null, 8 / 12]}>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {title && (
                <Box className={styles.title} marginBottom={[5, null, null, 5]}>
                  {title}
                </Box>
              )}
              {text && (
                <Box className={styles.text}>
                  <Markdown source={text} />
                </Box>
              )}
              {url && linkTitle && (
                <Box marginTop={[7, null, null, 7]}>
                  <ButtonGhost
                    className={styles.button}
                    to={url}
                    title={linkTitle}
                  >
                    {linkTitle}
                  </ButtonGhost>
                </Box>
              )}
            </Flex>
          </Column>
        </Row>
      </Container>
    </SbEditable>
  )
}

export default Banner
