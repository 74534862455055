import React from 'react'
import { Flex } from 'reflexbox'

import { Props } from './types'
import * as styles from './ProductGridFooter.module.scss'
import ButtonSecondary from '../UI/Button/ButtonSecondary/ButtonSecondary'

const ProductGridFooter = ({
  totalHits,
  loadedProducts,
  page,
  loadNextPage,
}: Props): JSX.Element => {
  return (
    <Flex
      width={1}
      paddingY={7}
      justifyContent="center"
      className={styles.wrapper}
    >
      <ButtonSecondary
        onClick={loadNextPage}
        disabled={loadedProducts >= totalHits}
      >
        {loadedProducts >= totalHits
          ? `Inga fler produkter att visa`
          : `Visa fler produkter (${loadedProducts}/${totalHits})`}
      </ButtonSecondary>
    </Flex>
  )
}

export default ProductGridFooter
