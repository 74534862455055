import React from 'react'
import { Flex, Box } from 'reflexbox'
import { useRecoilState } from 'recoil'

import { topListState, activePopupState } from 'src/recoil/atoms'
import Image from 'src/components/UI/Image/Image'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import ButtonFilled from 'src/components/UI/Button/ButtonFilled/ButtonFilled'
import FavoriteButton from 'src/components/Favorites/FavoriteButton/FavoriteButton'
import Badge from 'src/components/UI/Badge/Badge'
import { getTopListPosition } from 'src/helpers/getToplistPosition'
import { getFlag } from 'src/helpers/getFlag'
import { getCategoryName } from 'src/helpers/getCategoryStructure'
import { getVolumeInMillieLiters } from 'src/helpers/getVolumeInMillieLiters'

import { Props } from './types'
import * as styles from './ProductCard.module.scss'
import ProductLabel from './ProductLabel/ProductLabel'

const ProductCard = ({
  id,
  slug = '',
  name,
  media,
  category,
  producerName,
  countryName,
  regionName,
  labelNames,
  volume,
  price,
  status,
  url,
  ratings,
  badge,
  customTopListPosition = null,
  sku,
}: Props): JSX.Element => {
  const [, setActivePopup] = useRecoilState(activePopupState)
  const [topList] = useRecoilState(topListState)
  const categoryName = getCategoryName(category)
  const topListPosition = customTopListPosition
    ? customTopListPosition
    : topList
    ? getTopListPosition(topList, id)
    : null

  const volumeInMillieLiters = getVolumeInMillieLiters(volume)

  const thumbnail = media && media.length > 0 ? media[0] : null
  const Flag = getFlag(countryName)

  return (
    <Flex flexDirection="column" className={styles.wrapper}>
      <LinkComponent to={slug} title={name} className={styles.imageWrapper}>
        {thumbnail && (
          <Image
            className={styles.thumbnail}
            src={thumbnail}
            alt={name ? name : ''}
            sizes={{
              mobile: '50vw',
              tablet: '33vw',
              desktop: '33vw',
            }}
          />
        )}
        <Box className={styles.contentOverlay}>
          {(topListPosition || badge) && (
            <Badge
              position={topListPosition}
              badge={badge}
              className={styles.badge}
            />
          )}
          {ratings && ratings.length > 0 && (
            <Flex
              flexDirection="column"
              alignItems="flex-end"
              className={styles.ratings}
            >
              {ratings.map((rating, index) => {
                const { filename, alt } = rating?.image || {}
                return filename ? (
                  <Box
                    key={`ratings-${index}`}
                    className={styles.rating}
                    marginBottom={[4]}
                  >
                    <Image
                      src={filename}
                      alt={alt ? alt : ''}
                      sizes={{
                        mobile: '100px',
                        tablet: '100px',
                        desktop: '100px',
                      }}
                    />
                  </Box>
                ) : null
              })}
            </Flex>
          )}
          {labelNames &&
            labelNames.length > 0 &&
            labelNames
              .map((label, i) => (
                <ProductLabel
                  key={`goes-well-with-tag-${i}`}
                  type={label.value}
                >
                  {label.name}
                </ProductLabel>
              ))
              .slice(0, 2)}
        </Box>
      </LinkComponent>
      <Box paddingTop={[4, null, null, 6]} className={styles.content}>
        <FavoriteButton
          item={{
            sku,
            name,
            price,
            image: media?.length > 0 ? media[0] : null,
            slug,
            status,
            url,
            producerName,
          }}
          className={styles.favoriteButton}
        />

        {producerName && (
          <Box
            marginBottom={['2px', null, null, '2px']}
            className={styles.producer}
            as="span"
          >
            {producerName}
          </Box>
        )}
        {name && (
          <Box
            marginBottom={[1, null, null, 1]}
            className={styles.name}
            as="span"
          >
            {name}
          </Box>
        )}
        {(categoryName || countryName || volumeInMillieLiters) && (
          <Flex
            marginBottom={[2, null, null, 1]}
            className={styles.information}
            alignItems="center"
          >
            {Flag && (
              <Box
                marginTop={['5px', null, null, '5px']}
                marginRight={['6px', null, null, '6px']}
              >
                {Flag}
              </Box>
            )}
            <Flex
              flexDirection={['column', null, null, 'row']}
              alignItems={['flex-start', null, null, 'center']}
              className={styles.metadata}
              width={1}
            >
              <Box
                marginBottom={['-4px', null, null, 0]}
                marginRight={[0, null, null, 1]}
                className={styles.location}
              >
                {categoryName && categoryName}
                {countryName &&
                  ` från ${countryName}${
                    regionName &&
                    regionName.length &&
                    `, ${regionName.join(', ')}`
                  } `}
              </Box>
              <Box
                marginBottom={['-4px', null, null, 0]}
                flex={[null, null, null, '0 0 52px']}
              >
                {volumeInMillieLiters && ` • ${volumeInMillieLiters} ml`}
              </Box>
            </Flex>
          </Flex>
        )}

        {(price || status) && (
          <Flex justifyContent="space-between" alignItems="flex-end">
            {price && <Box className={styles.price}>{price} Sek</Box>}
            {status && (
              <Box className={styles.buyButton}>
                <ButtonFilled
                  to={status === 'available' && url ? '' : slug ? slug : ''}
                  onClick={
                    status === 'available' && url
                      ? () => setActivePopup({ url, name, price })
                      : null
                  }
                  title={name}
                >
                  {status === 'available' && url ? 'Köp' : 'Info'}
                </ButtonFilled>
              </Box>
            )}
          </Flex>
        )}
      </Box>
    </Flex>
  )
}

export default ProductCard
