import React, { useRef, useState, useEffect } from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { SbLink } from 'src/storyblok/types/storyblokTypes'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { Dash } from 'src/components/UI/Icon/Icon'

import * as styles from './LinkList.module.scss'
import { Props } from './types'

const LinkList = ({ blok }: Props): JSX.Element => {
  const { title, links } = blok

  return (
    <SbEditable content={blok}>
      <Container
        className={styles.wrapper}
        paddingTop={[14, null, null, 12]}
        paddingBottom={[13, null, null, 9]}
        paddingX={0}
      >
        <Row flexDirection="row" justifyContent="center" alignItems="center">
          {title && (
            <Column width={[1, null, null, 8 / 12]}>
              <Box className={styles.title} marginBottom={[3, null, null, 4]}>
                {title}
              </Box>
            </Column>
          )}
          {links && links.length > 0 && (
            <Column width={[1, null, null, 8 / 12]}>
              <Box className={styles.linksWrapper} height="100%">
                <Flex
                  alignItems="center"
                  flexWrap={['no-wrap', null, null, 'wrap']}
                  justifyContent={['flex-start', null, null, 'center']}
                  height="100%"
                >
                  {links.map((link: SbLink, index: number) => {
                    const { url, title } = link ? getLinkAttributes(link) : {}
                    return url && title ? (
                      <Flex
                        key={`link-list-item-${index}`}
                        className={styles.link}
                        paddingX={[2, null, null, 2]}
                        paddingLeft={[index === 0 ? 4 : 0, null, null, 0]}
                        marginBottom={[3, null, null, 3]}
                      >
                        <LinkComponent to={url} title={title}>
                          {title}
                        </LinkComponent>
                        {index !== links.length - 1 && (
                          <Box paddingLeft={[3, null, null, 3]}>
                            <Dash />
                          </Box>
                        )}
                      </Flex>
                    ) : null
                  })}
                </Flex>
              </Box>
            </Column>
          )}
        </Row>
      </Container>
    </SbEditable>
  )
}

export default LinkList
