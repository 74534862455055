import React, { useRef } from 'react'
import { Splide } from '@splidejs/react-splide'

import * as styles from './Carousel.module.scss'
import { Props } from './types'
import { ChevronLeft, ChevronRight } from '../UI/Icon/Icon'

// Childs need to be wrapped by SplideSlide
const Carousel = ({
  children,
  showControls = true,
  classNameControls,
  preventLastScroll = false,
}: Props): JSX.Element => {
  const ref = useRef()
  if (typeof window === 'undefined') {
    return <p>Server Render</p>
  }
  return (
    <Splide
      className={styles.wrapper}
      ref={ref}
      options={{
        gap: '24px',
        pagination: false,
        isNavigation: false,
        arrows: 'slider',
        rewind: true,
      }}
      onMoved={(splide: any, destIndex: any, prevIndex: any) => {
        if (preventLastScroll) {
          if (window && window.innerWidth > 1023) {
            if (destIndex === children.length - 2) {
              splide.go(0)
            }
            if (destIndex === children.length - 1) {
              splide.go(children.length - 3)
            }
          }
        }
      }}
      renderControls={() =>
        showControls ? (
          <div
            className={[
              'splide__arrows',
              styles.controls,
              classNameControls ? classNameControls : '',
            ].join(' ')}
          >
            <button
              className={['splide__arrow--prev', styles.buttonLeft].join(' ')}
            >
              <ChevronLeft />
            </button>
            <button
              className={['splide__arrow--next', styles.buttonRight].join(' ')}
            >
              <ChevronRight />
            </button>
          </div>
        ) : null
      }
    >
      {children}
    </Splide>
  )
}

export default Carousel
