import React from 'react'
import SbEditable from 'storyblok-react'

import Components from 'src/storyblok/Components'
// import styles from './PageHome.module.scss'

import { Props } from './types'

const PageHome = ({ blok }: Props): JSX.Element => {
  const { hero, content } = blok

  return (
    <SbEditable content={blok}>
      <main>
        {hero?.length > 0 &&
          hero.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
              isStart: true,
            })
          )}
        {content?.length > 0 &&
          content.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
      </main>
    </SbEditable>
  )
}

export default PageHome
