import * as React from 'react'
import { useState, useEffect } from 'react'
import { Box, Flex } from 'reflexbox'
import isNode from 'is-node'

import Markdown from 'src/components/Markdown/Markdown'
import { storageKeys } from 'src/constants'
import ButtonBorder from 'src/components/UI/Button/ButtonBorder/ButtonBorder'
import { Container } from 'src/components/UI/Grid/Grid'

import { Props, ContentProps } from './types'
import * as styles from './CookieConsent.module.scss'

const Content = ({ blok, cookie, onClick }: ContentProps): JSX.Element => {
  const { text }: any = blok?.content?.text ? blok.content : blok ? blok : {}

  return (
    <Box
      width={1}
      className={
        cookie.hide
          ? [styles.cookieWrapper, styles.hidden].join(' ')
          : styles.cookieWrapper
      }
    >
      <Flex
        paddingY={[6, null, null, 6]}
        width={1}
        className={styles.innerWrapper}
      >
        <Container>
          <Flex
            width={1}
            flexDirection={['column', null, 'row']}
            justifyContent={['flex-start', null, 'space-between']}
            alignItems="center"
          >
            <Box
              maxWidth={['570px']}
              textAlign={['center', null, 'left']}
              marginBottom={[2, null, 0]}
              width={[1, null, 7 / 12]}
            >
              {text && <Markdown source={text} />}
            </Box>
            <Flex
              justifyContent="flex-end"
              width={[1, null, 5 / 12]}
              marginTop={[2, null, 0]}
            >
              <ButtonBorder customIcon={<div />} onClick={onClick}>
                Okej
              </ButtonBorder>
            </Flex>
          </Flex>
        </Container>
      </Flex>
    </Box>
  )
}

const CookieConsent = ({ blok, preview }: Props): JSX.Element => {
  const [cookie, setCookie] = useState({ hide: false, show: false })

  useEffect(() => {
    if (!isNode) {
      const cookieAccepted = localStorage.getItem(storageKeys.COOKIE_ACCEPTED)
      if (!cookieAccepted) {
        setCookie({
          ...cookie,
          show: true,
        })
      }
    }
  }, [])

  const handleClick = () => {
    setCookie({
      ...cookie,
      hide: true,
    })
    setTimeout(() => {
      localStorage.setItem(storageKeys.COOKIE_ACCEPTED, JSON.stringify(true))
      setCookie({
        ...cookie,
        show: false,
      })
    }, 1000)
  }

  return !preview ? (
    <React.Fragment>
      {cookie.show ? (
        <Content blok={blok} cookie={cookie} onClick={() => handleClick()} />
      ) : null}
    </React.Fragment>
  ) : (
    <Content blok={blok} onClick={() => {}} cookie={cookie} />
  )
}

export default CookieConsent
