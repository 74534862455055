import { getDataSourceEntries } from 'src/helpers/getDataSourceData'

export const getOrderByDataSource = (
  arr: [],
  dataSource: string,
  key: string,
  customOrder: [],
  hasContent = true
) => {
  const order =
    customOrder?.length > 0
      ? customOrder
      : getDataSourceEntries(dataSource).map((source: any) => source.value)

  const sortedArray =
    arr?.length > 0
      ? arr.sort((a: any, b: any) => {
          let thisOrder = 1
          let nextOrder = 1
          if (hasContent && a.content && b.content) {
            thisOrder = order.indexOf(a.content[key])
            nextOrder = order.indexOf(b.content[key])
          } else {
            thisOrder = order.indexOf(a[key])
            nextOrder = order.indexOf(b[key])
          }
          return thisOrder < 0 ? 1 : nextOrder < 0 ? -1 : thisOrder - nextOrder
        })
      : []

  return sortedArray
}
