import React from 'react'
import { Flex, Box } from 'reflexbox'
import { useRecoilState } from 'recoil'
import classnames from 'classnames'

import { activeMenuState } from 'src/recoil/atoms'
import SortSelect from 'src/components/UI/SortSelect/SortSelect'
import ButtonLink from 'src/components/UI/Button/ButtonLink/ButtonLink'
import ButtonSecondary from 'src/components/UI/Button/ButtonSecondary/ButtonSecondary'
import Filters from 'src/components/Filters/Filters'
import { ControlsIcon } from 'src/components/UI/Icon/Icon'

import * as styles from './FiltersOverlay.module.scss'
import { Props } from './types'

const FiltersOverlay = ({
  facets,
  setFilter,
  setRangeFilter,
  activeFacets,
  resetAllFilters,
  sort,
  setSort,
  sortOptions,
  isFiltered,
  totalHits,
  availableFacets,
}: Props): JSX.Element => {
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)
  return (
    <Box
      className={classnames(
        styles.wrapper,
        activeMenu === 'filter' ? styles.active : ''
      )}
    >
      <Flex
        className={styles.filtersMobileContainer}
        paddingX={[4]}
        paddingTop={[2]}
        paddingBottom={['78px']}
        flexDirection="column"
      >
        <Flex
          className={styles.filtersPanel}
          alignItems={['center']}
          justifyContent="space-between"
          paddingY={[5]}
        >
          <Flex alignItems="center" className={styles.showFilter}>
            <Box as="span" marginTop={[1]} marginRight={[2]}>
              Filtrera & sortera
            </Box>
            <ControlsIcon />
          </Flex>
          {isFiltered && (
            <ButtonLink onClick={() => resetAllFilters()}>
              Rensa allt
            </ButtonLink>
          )}
        </Flex>
        <Flex
          className={styles.filtersPanel}
          alignItems={['center']}
          justifyContent="space-between"
          paddingY={[5]}
        >
          <SortSelect
            className={styles.select}
            onChange={(e) => {
              if (e.target.value) {
                setSort(e.target.value)
              }
            }}
            value={sort}
            options={sortOptions}
          />
        </Flex>
        <Filters
          availableFacets
          isFiltered={isFiltered}
          facets={facets}
          setFilter={setFilter}
          setRangeFilter={setRangeFilter}
          activeFacets={activeFacets}
        />
      </Flex>
      <Flex
        className={styles.actionArea}
        alignItems="center"
        justifyContent="center"
        padding={[4]}
      >
        <ButtonSecondary
          className={styles.button}
          onClick={() => setActiveMenu('')}
        >
          {`Visa produkter (${totalHits})`}
        </ButtonSecondary>
      </Flex>
    </Box>
  )
}

export default FiltersOverlay
