import React from 'react'
import { Box, Flex } from 'reflexbox'
import { Container } from 'src/components/UI/Grid/Grid'
import SbEditable from 'storyblok-react'
import * as styles from './ContactGrid.module.scss'
import { Props } from './types'
import Components from 'src/storyblok/Components'

const ContactGrid = ({ blok }: Props) => {
  const { title, contact_persons } = blok

  return (
    <SbEditable content={blok}>
      <Container className={styles.wrapper}>
        <Flex paddingTop={[8, null, null, 11]} justifyContent="center">
          <Flex width={[1, null, null, 10 / 12]} flexDirection="column">
            {title && (
              <Box width={1}>
                <Box
                  width={[1, null, null, 4 / 10]}
                  className={styles.title}
                  as="p"
                >
                  {title}
                </Box>
              </Box>
            )}
            <Box
              marginTop={title ? [4, null, null, 6] : 0}
              marginBottom={[8, null, null, 10]}
              className={styles.grid}
            >
              {contact_persons?.length > 0 &&
                contact_persons.map((blok: any) =>
                  React.createElement(Components(blok.component), {
                    key: blok._uid,
                    blok: blok,
                  })
                )}
            </Box>
          </Flex>
        </Flex>
      </Container>
    </SbEditable>
  )
}

export default ContactGrid
