import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'

import { Props } from './types'
import * as styles from './ProductLabel.module.scss'

const ProductLabel = ({
  children,
  type,
  rounded = false,
}: Props): JSX.Element => {
  const wrapperClassName = classnames(
    styles.wrapper,
    rounded ? styles.rounded : '',
    type && type === 'biodynamic'
      ? styles.coral
      : type && type === 'ecological'
      ? styles.darkGreen
      : type && type === 'vegan'
      ? styles.rose
      : type && type === 'sustainable'
      ? styles.mediumGreen
      : ''
  )
  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      className={wrapperClassName}
    >
      <Flex
        className={styles.label}
        justifyContent="flex-start"
        alignItems="center"
      >
        <Box className={styles.text}>{children}</Box>
      </Flex>
    </Flex>
  )
}

export default ProductLabel
