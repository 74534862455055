import React from 'react'
import { Box, Flex } from 'reflexbox'

import { Row, Column } from 'src/components/UI/Grid/Grid'
import ButtonText from 'src/components/UI/Button/ButtonText/ButtonText'
import { getFlag } from 'src/helpers/getFlag'
import { getCategoryName } from 'src/helpers/getCategoryStructure'
import { getVolumeInMillieLiters } from 'src/helpers/getVolumeInMillieLiters'

import Image from '../UI/Image/Image'
import * as styles from './ProductHighlight.module.scss'
import { Props } from './types'

const ProductHighlight = ({ product, label, type }: Props): JSX.Element => {
  const {
    slug = '',
    name,
    description,
    media,
    category,
    countryName,
    regionName,
    volume,
    price,
  } = product
  const categoryName = getCategoryName(category)
  const thumbnail = media && media.length > 0 ? media[0] : null
  const Flag = getFlag(countryName)
  const volumeInMillieLiters = getVolumeInMillieLiters(volume)
  return (
    <Row
      className={styles.wrapper}
      alignItems="center"
      justifyContent="flex-end"
    >
      <Column width={[1, null, null, 2 / 6]}>
        <Flex justifyContent="flex-end" paddingX={[6, null, null, 0]}>
          {thumbnail && (
            <Box className={styles.image} marginBottom={[5]}>
              <Image
                src={thumbnail}
                alt={name}
                sizes={{
                  mobile: '50vw',
                  tablet: '33vw',
                  desktop: '33vw',
                }}
              />
            </Box>
          )}
        </Flex>
      </Column>
      <Column width={[1, null, null, type === 'large' ? 4 / 6 : 3 / 6]}>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
          paddingX={[6, null, null, 0]}
        >
          {label && (
            <Box className={styles.label} marginBottom={[2, null, null, 2]}>
              {label}
            </Box>
          )}

          {name && (
            <Box
              className={styles.title}
              as="span"
              marginBottom={[3, null, null, 3]}
            >
              {name}
            </Box>
          )}

          {(categoryName || countryName || volume) && (
            <Flex marginBottom={[4, null, null, 4]} alignItems="center">
              {Flag && (
                <Box
                  marginTop={['2px', null, null, '2px']}
                  marginRight={['6px', null, null, '6px']}
                >
                  {Flag}
                </Box>
              )}
              <Box className={styles.metadata}>
                <Box as="span">
                  {categoryName && categoryName}
                  {countryName && ` från ${countryName}${regionName && `, `}`}
                </Box>
                <Box as="span">
                  {regionName && `${regionName}`}
                  {volumeInMillieLiters && ` • ${volumeInMillieLiters} ml`}
                </Box>
              </Box>
            </Flex>
          )}

          {price && (
            <Box className={styles.price} marginBottom={[3, null, null, 3]}>
              {price} Sek
            </Box>
          )}

          {description && (
            <Box
              className={styles.description}
              as="span"
              marginBottom={[3, null, null, 3]}
            >
              {description}
            </Box>
          )}

          {slug && (
            <ButtonText to={`/${slug}`} title={name}>
              Läs mer
            </ButtonText>
          )}
        </Flex>
      </Column>
    </Row>
  )
}

export default ProductHighlight
