import React, { Fragment } from 'react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './InfoGrid.module.scss'
import { Props } from './types'

export const InfoGrid = ({
  country,
  grapes,
  regions,
  year,
  producer,
  assortment,
  producerLink,
  countryLink,
  regionLinks,
}: Props): JSX.Element => (
  <Box className={classnames(styles.infoGrid, 'hidePrint')}>
    <Flex className={styles.column} flexDirection="column">
      <Box className={styles.infoLabel} as="span">
        Land
      </Box>
      {countryLink ? (
        <LinkComponent className={styles.infoValue} to={countryLink}>
          {country}
        </LinkComponent>
      ) : (
        <Box className={styles.infoValue} as="span">
          {country}
        </Box>
      )}
    </Flex>
    <Flex
      paddingLeft={[0, null, null, 4]}
      className={styles.column}
      flexDirection="column"
    >
      <Box className={styles.infoLabel} as="span">
        Druvor
      </Box>
      <Box className={styles.infoValue} as="span">
        {grapes && grapes}
      </Box>
    </Flex>
    <Flex className={styles.column} flexDirection="column">
      <Box className={styles.infoLabel} as="span">
        Region
      </Box>
      <Box className={styles.infoValue} as="span">
        {regions &&
          regions.map((reg: any, index: number) =>
            regionLinks ? (
              <Fragment key={reg}>
                <span>{index !== 0 ? ',' : ''}</span>
                {regionLinks && regionLinks[index] ? (
                  <LinkComponent to={regionLinks[index]}>{reg}</LinkComponent>
                ) : (
                  reg
                )}
              </Fragment>
            ) : null
          )}
      </Box>
      {/* {regionLink ? (
        <LinkComponent className={styles.infoValue} to={regionLink}>
          {regions && regions}
        </LinkComponent>
      ) : (
        <Box className={styles.infoValue} as="span">
          {regions && regions}
        </Box>
      )} */}
    </Flex>
    <Flex
      paddingLeft={[0, null, null, 4]}
      className={styles.column}
      flexDirection="column"
    >
      <Box className={styles.infoLabel} as="span">
        Årgång
      </Box>
      <Box className={styles.infoValue} as="span">
        {year}
      </Box>
    </Flex>

    <Flex className={styles.column} flexDirection="column">
      <Box className={styles.infoLabel} as="span">
        Producent
      </Box>
      {producerLink ? (
        <LinkComponent className={styles.infoValue} to={producerLink}>
          {producer && producer}
        </LinkComponent>
      ) : (
        <Box className={styles.infoValue} as="span">
          {producer && producer}
        </Box>
      )}
    </Flex>
    <Flex
      paddingLeft={[0, null, null, 4]}
      className={styles.column}
      flexDirection="column"
    >
      <Box className={styles.infoLabel} as="span">
        Sortiment
      </Box>
      <Box className={styles.infoValue} as="span">
        {assortment}
      </Box>
    </Flex>
  </Box>
)
