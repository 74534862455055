import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'

import Components from 'src/storyblok/Components'
import Image from 'src/components/UI/Image/Image'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './AboutProduct.module.scss'
import { Props } from './types'

export const AboutProduct = ({
  image,
  productDescription,
  productTaste,
  tasteProfiles,
}: Props): JSX.Element => {
  return (
    <Flex
      flexDirection={['column', null, null, 'row']}
      className={styles.wrapper}
    >
      <Box
        width={[1, null, null, 1 / 2]}
        className={classnames(styles.imageWrapper, 'hideMobile')}
      >
        <Box className={styles.image}>
          {image?.filename && (
            <Image
              src={image.filename}
              alt={image?.alt ? image.alt : 'Produktbeskrivning'}
            />
          )}
        </Box>
      </Box>
      <Flex
        flexDirection="column"
        justifyContent="center"
        width={[1, null, null, 1 / 2]}
        paddingX={[4, null, null, 20]}
        paddingY={[8, null, null, 19]}
        className={styles.information}
      >
        {productTaste && (
          <Box className={styles.taste} marginBottom={[6, null, null, 6]}>
            <Box
              className={styles.labelLight}
              marginBottom={[1, null, null, 1]}
            >
              {`Karaktär`}
            </Box>
            <Box className={styles.text}>
              <Markdown source={productTaste} />
            </Box>
          </Box>
        )}
        {productDescription && (
          <Box className={styles.description} marginBottom={[6, null, null, 6]}>
            <Box
              className={styles.labelLight}
              marginBottom={[1, null, null, 1]}
            >
              {`Om vinet`}
            </Box>
            <Box className={styles.text}>
              <Markdown source={productDescription} />
            </Box>
          </Box>
        )}

        <Box>
          {tasteProfiles?.length > 0 && (
            <Flex
              justifyContent={[
                tasteProfiles?.length > 2 ? 'space-between' : 'flex-start',
                null,
                null,
                'flex-start',
              ]}
            >
              {tasteProfiles?.length > 0 &&
                tasteProfiles.map((blok: any) => (
                  <Box
                    key={blok._uid}
                    marginRight={[
                      tasteProfiles?.length > 2 ? 0 : 8,
                      null,
                      null,
                      16,
                    ]}
                  >
                    {React.createElement(Components(blok.component), {
                      key: blok._uid,
                      blok: blok,
                    })}
                  </Box>
                ))}
            </Flex>
          )}
        </Box>
      </Flex>
    </Flex>
  )
}
