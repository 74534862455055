export const getColor = (color: string) => {
  switch (color) {
    case 'purple':
      return '#ABB5C6'
    case 'peach':
      return '#EBC1A9'
    case 'turqoise':
      return '#AFC7BA'
    case 'darkBeige':
      return '#e9e5db'
    case 'mediumBeige':
      return '#f3f0e8'
    case 'lightBeige':
      return '#fbf8f2'
    default:
      return '#fbf8f2'
  }
}
