import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { SplideSlide } from '@splidejs/react-splide'

import { parseStoryblokProductToAlgolia } from 'src/algolia/clienthelpers'
import { Product } from 'src/globalTypes/types'
import ProductCard from 'src/components//ProductCard/ProductCard'
import ButtonText from 'src/components/UI/Button/ButtonText/ButtonText'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import Carousel from 'src/components/Carousel/Carousel'
import { getColor } from 'src/helpers/getColor'

import { Props } from './types'
import * as styles from './ProductList.module.scss'

const ProductList = ({ blok, theme }: Props): JSX.Element => {
  const { title, products, link, mode } = blok
  const { url, title: linkTitle } =
    link && link?.length > 0 ? getLinkAttributes(link[0]) : {}

  const background = getColor(theme)
  const type =
    products && products.length > 3
      ? 'carousel'
      : products && products.length > 0 && products.length < 4
      ? 'grid'
      : 'none'
  return (
    <SbEditable content={blok}>
      <Box
        as="section"
        className={styles.wrapper}
        paddingTop={[16, null, null, 16]}
        paddingBottom={[18, null, null, 18]}
        bg={background}
      >
        <Container>
          <Flex
            justifyContent={
              type === 'grid' && !url && !linkTitle ? 'center' : 'space-between'
            }
            alignItems="flex-end"
            marginBottom={[10, null, null, 14]}
          >
            {title && (
              <Box
                className={styles.title}
                width={[url && linkTitle ? 3 / 4 : 'auto', null, null, 'auto']}
              >
                {title}
              </Box>
            )}
            {url && linkTitle && (
              <Flex
                justifyContent="flex-end"
                width={[3 / 6, null, null, 'auto']}
              >
                <ButtonText
                  className={styles.button}
                  to={url}
                  title={linkTitle}
                >
                  {linkTitle}
                </ButtonText>
              </Flex>
            )}
          </Flex>
        </Container>
        {type === 'carousel' ? (
          <Box width={1} style={{ overflow: 'hidden' }}>
            <Box
              marginLeft={[5, null, null, `${(1 / 12) * 100}%`]}
              width={[4 / 6, null, null, 3 / 12]}
            >
              <Carousel preventLastScroll>
                {products &&
                  products.length > 0 &&
                  products.map((product: Product, index: number) => {
                    const parsedProduct = product?.id
                      ? parseStoryblokProductToAlgolia(product)
                      : {}
                    return parsedProduct.id ? (
                      <SplideSlide key={parsedProduct.id}>
                        <ProductCard
                          {...parsedProduct}
                          customTopListPosition={
                            mode === 'top-list' ? index + 1 : null
                          }
                        />
                      </SplideSlide>
                    ) : null
                  })}
              </Carousel>
            </Box>
          </Box>
        ) : type === 'grid' ? (
          <Container>
            <Row
              justifyContent={['flex-start', null, null, 'center']}
              flexDirection="row"
            >
              {products &&
                products.length > 0 &&
                products.map((product: Product, index: number) => {
                  const parsedProduct = product?.id
                    ? parseStoryblokProductToAlgolia(product)
                    : {}

                  return (
                    <Column
                      key={`product-grid-${index}`}
                      width={[1 / 2, null, null, 1 / 4]}
                      marginBottom={[4, null, null, 6]}
                    >
                      <ProductCard
                        {...parsedProduct}
                        customTopListPosition={
                          mode === 'top-list' ? index + 1 : null
                        }
                      />
                    </Column>
                  )
                })}
            </Row>
          </Container>
        ) : null}
      </Box>
    </SbEditable>
  )
}

export default ProductList
