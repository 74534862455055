import React from 'react'
import classnames from 'classnames'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './Review.module.scss'
import { Props } from './types'

const Review = ({ blok }: Props): JSX.Element => {
  const { title, quote, author } = blok

  const wrapperStyles = classnames(styles.wrapper)
  return (
    <SbEditable content={blok}>
      <Box
        className={wrapperStyles}
        width={[1, null, null, 4 / 12]}
        paddingX={[4, null, null, 6]}
      >
        {title && <Box className={styles.title}>{title}</Box>}
        {quote && (
          <Box className={styles.quote} marginTop={[4, null, null, 4]}>
            <Markdown source={quote} />
          </Box>
        )}
        {author && (
          <Box className={styles.author} marginTop={[4, null, null, 4]}>
            {`– `}
            {author}
          </Box>
        )}
      </Box>
    </SbEditable>
  )
}

export default Review
