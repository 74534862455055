import React from 'react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'

import { ControlsIcon } from 'src/components/UI/Icon/Icon'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'

import * as styles from './ButtonControl.module.scss'
import { Props } from './types'

const ButtonContent = ({ children }: Props): JSX.Element => (
  <Flex
    alignItems="center"
    paddingTop={[2, null, null, 2]}
    paddingBottom={[1, null, null, 1]}
  >
    <Box className={styles.text} textAlign="left" marginRight={[2]}>
      {children}
    </Box>
    <ControlsIcon />
  </Flex>
)

const ButtonControl = ({
  onClick,
  to,
  title,
  children,
  className = '',
  customIcon,
  theme = '',
  useIcon = true,
}: Props): JSX.Element => {
  const buttonStyles = classnames(
    styles.button,

    className
  )
  return onClick && !to ? (
    <Flex as="button" onClick={onClick} className={buttonStyles}>
      <ButtonContent customIcon={customIcon} useIcon={useIcon}>
        {children}
      </ButtonContent>
    </Flex>
  ) : to ? (
    <LinkComponent
      to={to}
      onClick={onClick}
      title={title}
      className={buttonStyles}
    >
      <ButtonContent customIcon={customIcon} useIcon={useIcon}>
        {children}
      </ButtonContent>
    </LinkComponent>
  ) : (
    <div />
  )
}

export default ButtonControl
