// extracted by mini-css-extract-plugin
export var description = "PageProduct-module--description--eknLf";
export var dynamicInformation = "PageProduct-module--dynamicInformation--6qnXB";
export var favoriteButtonMobile = "PageProduct-module--favoriteButtonMobile--mGm-W";
export var infoGrid = "PageProduct-module--infoGrid--B5OqH";
export var label = "PageProduct-module--label--ox4rE";
export var metaInformation = "PageProduct-module--metaInformation--S7py1";
export var price = "PageProduct-module--price--ITMvA";
export var priceWrapper = "PageProduct-module--priceWrapper--imsVv";
export var printBlock = "PageProduct-module--printBlock--bLoC0";
export var printButton = "PageProduct-module--printButton--8UWE0";
export var printButtonText = "PageProduct-module--printButtonText--u1Eml";
export var printInformation = "PageProduct-module--printInformation--QSt7h";
export var printLabel = "PageProduct-module--printLabel--wPiyC";
export var printText = "PageProduct-module--printText--uN-2D";
export var printTitle = "PageProduct-module--printTitle--eFGv2";
export var producer = "PageProduct-module--producer--sSDvq";
export var productHeader = "PageProduct-module--productHeader--Nlpkj";
export var productSection = "PageProduct-module--productSection--dLQXK";
export var quote = "PageProduct-module--quote--84vxD";
export var readMore = "PageProduct-module--readMore--FmtyP";
export var show = "PageProduct-module--show--hdv++";
export var snackBar = "PageProduct-module--snackBar--7LAXz";
export var snackPrice = "PageProduct-module--snackPrice--KOmEg";
export var snackTitle = "PageProduct-module--snackTitle--VAi+a";
export var statusText = "PageProduct-module--statusText--NFJ3p";
export var tax = "PageProduct-module--tax--1VyZQ";
export var title = "PageProduct-module--title--YSFUx";