import React from 'react'
import { Flex, Box } from 'reflexbox'
import classnames from 'classnames'
import { useRecoilState } from 'recoil'
import { useLocation } from '@reach/router'

import { gtagEvent } from 'src/components/UI/GoogleAnalytics/GoogleAnalytics'
import { favoritesState } from 'src/recoil/atoms'
import { Heart, HeartFilled } from 'src/components/UI/Icon/Icon'
import {
  getUpdatedFavoritesArray,
  isFavorite,
} from 'src/helpers/favoriteHelpers'

import * as styles from './FavoriteButton.module.scss'
import { Props } from './types'

const FavoriteButton = ({ item, className }: Props): JSX.Element => {
  const [activeFavorites, setActiveFavorites] = useRecoilState(favoritesState)
  const locationHref = useLocation ? useLocation().href : ''

  const addToFavorite = () => {
    setActiveFavorites(getUpdatedFavoritesArray(item, activeFavorites))
  }
  return (
    <Flex
      as="button"
      className={classnames(styles.wrapper, className)}
      onClick={() => {
        addToFavorite()
        if (!isFavorite(item, activeFavorites)) {
          gtagEvent({
            action: item?.name,
            category: 'Click - Favourite',
            label: locationHref,
            value: item?.price,
          })
        }
      }}
      aria-label="Add to favorites"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        display="inline-flex"
        className={classnames(
          styles.icon,
          isFavorite(item, activeFavorites) ? styles.isActive : ''
        )}
      >
        <Heart width={26} height={26} />
        <HeartFilled className={styles.activeIcon} width={26} height={26} />
      </Box>
    </Flex>
  )
}

export default FavoriteButton
