import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Image from 'src/components/UI/Image/Image'

import * as styles from './QuoteAndImage.module.scss'
import { Props } from './types'

const QuoteAndImage = ({ blok }: Props): JSX.Element => {
  const { quote, image } = blok

  return (
    <SbEditable content={blok}>
      <Flex
        className={styles.wrapper}
        flexDirection={['column', null, null, 'row']}
      >
        <Box width={[1, null, null, 1 / 2]}>
          <Flex
            className={styles.quoteContent}
            paddingX={[4, null, null, 20]}
            paddingY={[25, null, null, 0]}
            flexDirection="column"
            justifyContent="center"
          >
            {quote && (
              <Box className={styles.quote} as="blockquote">
                {quote}
              </Box>
            )}
          </Flex>
        </Box>
        <Box width={[1, null, null, 1 / 2]}>
          <Box className={styles.imageWrapper} width={1}>
            {image?.filename && (
              <Image src={image.filename} alt={image.alt ? image.alt : ''} />
            )}
          </Box>
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default QuoteAndImage
