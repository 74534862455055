import React from 'react'
import { Flex, Box } from 'reflexbox'
import { useRecoilState } from 'recoil'
import classnames from 'classnames'

import { activeMenuState } from 'src/recoil/atoms'
import SortSelect from 'src/components/UI/SortSelect/SortSelect'
import ButtonLink from 'src/components/UI/Button/ButtonLink/ButtonLink'
import ButtonControl from 'src/components/UI/Button/ButtonControl/ButtonControl'
import {
  EraseIcon,
  OneColumnIcon,
  TwoColumnIcon,
} from 'src/components/UI/Icon/Icon'
import { getDataSourceEntryName } from 'src/helpers/getDataSourceData'

import * as styles from './Toolbar.module.scss'
import { Props } from './types'

const Toolbar = ({
  activeFacets,
  setFilter,
  showFilter,
  setShowFilter,
  resetAllFilters,
  sort,
  setSort,
  sortOptions,
  gridType,
  setGridType,
  isFiltered,
}: Props): JSX.Element => {
  const [activeMenu, setActiveMenu] = useRecoilState(activeMenuState)

  const hasActiveFacets =
    activeFacets &&
    Object.keys(activeFacets).length &&
    Object.keys(activeFacets).filter(
      (facetKey) =>
        (facetKey === 'price' &&
          activeFacets[facetKey] &&
          activeFacets[facetKey].length > 0) ||
        (Array.isArray(activeFacets[facetKey]) &&
          activeFacets[facetKey].length > 0 &&
          facetKey !== 'search')
    ).length > 0

  return (
    <React.Fragment>
      <Flex
        className={classnames(styles.toolbar, 'hideDesktop')}
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <Flex alignItems="center" justifyContent="flex-start" width={1 / 2}>
          <ButtonControl
            className={classnames(styles.showFilter, 'hideMobile')}
            onClick={() => {
              if (activeMenu === 'filter') {
                setActiveMenu('')
              } else {
                setActiveMenu('filter')
              }
            }}
          >
            {activeMenu === 'filter' ? 'Stäng filter' : 'Visa filter'}
          </ButtonControl>
          <ButtonControl
            className={classnames(styles.showFilter, 'hideDesktop')}
            onClick={() => {
              if (activeMenu === 'filter') {
                setActiveMenu('')
              } else {
                setActiveMenu('filter')
              }
            }}
          >
            {activeMenu === 'filter'
              ? 'Filtrera & sortera'
              : 'Filtrera & sortera'}
          </ButtonControl>
        </Flex>
        <Flex width={1 / 2} justifyContent="flex-end" alignItems="center">
          <Box
            as="button"
            className={classnames(
              styles.gridButton,
              gridType === 'one' ? styles.active : ''
            )}
            onClick={() => setGridType('one')}
          >
            <OneColumnIcon />
          </Box>
          <Box
            as="button"
            className={classnames(
              styles.gridButton,
              gridType === 'two' ? styles.active : ''
            )}
            onClick={() => setGridType('two')}
            paddingRight={0}
          >
            <TwoColumnIcon />
          </Box>
        </Flex>
        {hasActiveFacets && (
          <Flex
            className={styles.facetsMobile}
            alignItems="center"
            width={1}
            justifyContent="space-between"
            paddingY={2}
          >
            {Object.keys(activeFacets).map((facetKey: string) => {
              const values = activeFacets[facetKey]
              if (facetKey === 'search') {
                return null
              }
              if (facetKey === 'price' && values && values.length > 0) {
                // const prices = values.explode(',')
                return (
                  <Flex
                    alignItems="center"
                    className={styles.filter}
                    paddingX={[2, null, null, 2]}
                    paddingTop={[1, null, null, 1]}
                    paddingBottom={[0, null, null, 0]}
                    key={`active-filterprice}`}
                    marginRight={[2, null, null, 2]}
                    onClick={() => setFilter({ facet: facetKey, value: 0 })}
                  >
                    <Box as="span" marginRight={[2]}>
                      {`${values[0]} sek - ${values[1]} sek`}
                    </Box>
                    <EraseIcon className={styles.filterIcon} />
                  </Flex>
                )
              }
              return (
                Array.isArray(values) &&
                values.map((value: string) => {
                  return (
                    <Flex
                      alignItems="center"
                      className={styles.filter}
                      paddingX={[2, null, null, 2]}
                      paddingTop={[2, null, null, 2]}
                      paddingBottom={[1, null, null, 1]}
                      key={`active-filter-${value}`}
                      marginRight={[2, null, null, 2]}
                      onClick={() => setFilter({ facet: facetKey, value })}
                    >
                      <Box as="span" marginRight={[2]}>
                        {getDataSourceEntryName(value)}
                      </Box>
                      <EraseIcon className={styles.filterIcon} />
                    </Flex>
                  )
                })
              )
            })}
            {isFiltered && (
              <ButtonLink onClick={() => resetAllFilters()}>
                Rensa allt
              </ButtonLink>
            )}
          </Flex>
        )}
      </Flex>
      <Flex
        className={classnames(styles.toolbar, 'hideMobile')}
        alignItems="center"
        justifyContent="space-between"
      >
        <Flex alignItems="center" justifyContent="flex-start">
          <ButtonControl
            className={styles.showFilter}
            onClick={() => setShowFilter(!showFilter)}
          >
            {showFilter ? 'Stäng filter' : 'Visa filter'}
          </ButtonControl>
          <Flex marginLeft={[8, null, null, 8]} alignItems="center">
            {activeFacets &&
              Object.keys(activeFacets).length > 0 &&
              Object.keys(activeFacets).map((facetKey: string) => {
                const values = activeFacets[facetKey]
                if (facetKey === 'search') {
                  return null
                }
                if (facetKey === 'price' && values && values.length > 0) {
                  // const prices = values.explode(',')
                  return (
                    <Flex
                      alignItems="center"
                      className={styles.filter}
                      paddingX={[2, null, null, 2]}
                      paddingTop={[2, null, null, 2]}
                      paddingBottom={[1, null, null, 1]}
                      key={`active-filterprice}`}
                      marginRight={[2, null, null, 2]}
                      onClick={() => setFilter({ facet: facetKey, value: 0 })}
                    >
                      <Box as="span" marginRight={[2]}>
                        {`${values[0]} sek - ${values[1]} sek`}
                      </Box>
                      <EraseIcon className={styles.filterIcon} />
                    </Flex>
                  )
                }
                return (
                  Array.isArray(values) &&
                  values.map((value: string) => {
                    return (
                      <Flex
                        alignItems="center"
                        className={styles.filter}
                        paddingX={[2, null, null, 2]}
                        paddingTop={[2, null, null, 2]}
                        paddingBottom={[1, null, null, 1]}
                        key={`active-filter${value}`}
                        marginRight={[2, null, null, 2]}
                        onClick={() => setFilter({ facet: facetKey, value })}
                      >
                        <Box as="span" marginRight={[2]}>
                          {getDataSourceEntryName(value)}
                        </Box>
                        <EraseIcon className={styles.filterIcon} />
                      </Flex>
                    )
                  })
                )
              })}
            {isFiltered && (
              <ButtonLink onClick={() => resetAllFilters()}>
                Rensa allt
              </ButtonLink>
            )}
          </Flex>
        </Flex>
        <Flex justifyContent="flex-end">
          <SortSelect
            onChange={(e) => {
              if (e.target.value) {
                setSort(e.target.value)
              }
            }}
            value={sort}
            options={sortOptions}
          />
        </Flex>
      </Flex>
    </React.Fragment>
  )
}

export default Toolbar
