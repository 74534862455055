import React from 'react'
import classnames from 'classnames'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
import { SplideSlide } from '@splidejs/react-splide'

import Slider from 'src/components/Slider/Slider'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import Components from 'src/storyblok/Components'

import * as styles from './Reviews.module.scss'
import { Props } from './types'

const Reviews = ({ blok }: Props): JSX.Element => {
  const { reviews } = blok
  const wrapperStyles = classnames(styles.wrapper)
  return (
    <SbEditable content={blok}>
      <Container
        className={wrapperStyles}
        paddingTop={[16, null, null, 27]}
        paddingBottom={[20, null, null, 27]}
        paddingX={[0, null, null, 0]}
      >
        <Box>
          {reviews?.length > 1 ? (
            <Slider className={styles.slider}>
              {reviews.map((blok: any) => {
                return (
                  <SplideSlide key={blok._uid}>
                    <Flex justifyContent="center" width={1}>
                      {React.createElement(Components(blok.component), {
                        key: blok._uid,
                        blok: blok,
                      })}
                    </Flex>
                  </SplideSlide>
                )
              })}
            </Slider>
          ) : (
            <React.Fragment>
              {reviews.map((blok: any) => {
                return (
                  <Flex key={blok._uid} justifyContent="center" width={1}>
                    {React.createElement(Components(blok.component), {
                      key: blok._uid,
                      blok: blok,
                    })}
                  </Flex>
                )
              })}
            </React.Fragment>
          )}
        </Box>
      </Container>
    </SbEditable>
  )
}

export default Reviews
