import React from 'react'
import { Box, Flex } from 'reflexbox'
import { Container } from 'src/components/UI/Grid/Grid'
import Image from 'src/components/UI/Image/Image'
import SbEditable from 'storyblok-react'
import * as styles from './CatalogDownload.module.scss'
import { Props } from './types'

const CatalogDownload = ({ blok }: Props) => {
  const { label, title, file, thumbnail } = blok

  return (
    <SbEditable content={blok}>
      <Flex
        paddingTop={[14, null, 29]}
        paddingBottom={[10, null, null, 29]}
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
        alignItems="center"
      >
        <Flex
          alignItems={['flex-start']}
          justifyContent={'center'}
          flexDirection="column"
          width={[1, null, 10 / 12, 1 / 2]}
          paddingX={[0, null, null, 15]}
          marginBottom={[20, null, 29, 0]}
        >
          <Container>
            <Box marginBottom={[2]} className={styles.label} as="span">
              {label}
            </Box>
            <Box marginBottom={[6]} className={styles.title} as="p">
              {title}
            </Box>
            <Box>
              {file && file.filename && (
                <a
                  className={styles.link}
                  href={file.filename}
                  target="_blank"
                  download
                >
                  Ladda ner
                </a>
              )}
            </Box>
          </Container>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          width={[1, null, null, 1 / 2]}
        >
          <Box width={[2 / 3, null, 1 / 3, 1 / 2]}>
            {thumbnail && thumbnail.filename && (
              <Image src={thumbnail.filename} alt={thumbnail.filename} />
            )}
          </Box>
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default CatalogDownload
