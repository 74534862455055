import React from 'react'
import loadable from '@loadable/component'
import { Flex, Box } from 'reflexbox'

import * as styles from './GoesWellWith.module.scss'
import { Props } from './types'

const Taste = loadable(() => import('../../UI/Taste/Taste'))

export const GoesWellWith = ({ goesWellWith, spacing }: Props): JSX.Element => {
  return (
    <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
      {goesWellWith?.length > 0 &&
        goesWellWith.map((item) => {
          const { name, value } = item
          return (
            <Flex
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              key={value}
              width={[1 / 3]}
              paddingX={[spacing ? 6 : 0]}
              marginBottom={[6]}
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                marginBottom={[2]}
                width={['32px']}
                height={['32px']}
              >
                <Taste taste={value} />
              </Flex>
              <Box className={styles.label}>{name}</Box>
            </Flex>
          )
        })}
    </Flex>
  )
}
