import React from 'react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'

import SEO from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import Markdown from 'src/components/Markdown/Markdown'
import { Container } from 'src/components/UI/Grid/Grid'
import Image from 'src/components/UI/Image/Image'
import Components from 'src/storyblok/Components'
import PageNav from 'src/components/PageNav/PageNav'

import * as styles from './PageContent.module.scss'
import { Props } from './types'

const PageContent = ({ blok, full_slug }: Props) => {
  const {
    navItems,
    header_image,
    header_image_mobile,
    label,
    content,
    body,
    seo_data,
  } = blok

  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    image: header_image?.filename || '',
  })

  const hasMobileImage = header_image_mobile && header_image_mobile.filename

  return (
    <Flex width={1} flexDirection="column">
      <SEO
        url={full_slug}
        title={seo_title}
        metaDescription={seo_description}
        image={seo_image}
      />
      <Box className={styles.header}>
        {header_image && header_image.filename && (
          <Image
            className={hasMobileImage ? 'hideMobile' : ''}
            src={header_image.filename}
            sizes={{
              mobile: '100vw',
              tablet: '100vw',
              desktop: '100vw',
            }}
            alt={label}
          />
        )}
        {header_image_mobile && header_image_mobile.filename && (
          <Image
            className="hideDesktop"
            src={header_image_mobile.filename}
            sizes={{
              mobile: '100vw',
              tablet: '100vw',
              desktop: '100vw',
            }}
            alt={label}
          />
        )}
      </Box>
      {navItems?.length > 0 && (
        <PageNav
          items={navItems.map((item: any) => ({
            ...item,
            active: full_slug === item.slug,
          }))}
        />
      )}
      {(label || content) && (
        <Container>
          <Flex justifyContent="center" width={1}>
            <Box
              paddingY={[8, null, null, 16]}
              width={[1, null, null, 6 / 12]}
              className={styles.content}
            >
              {label && (
                <Box
                  display="block"
                  marginBottom={2}
                  className={styles.label}
                  as="span"
                >
                  {label}
                </Box>
              )}
              <Markdown source={content} />
            </Box>
          </Flex>
        </Container>
      )}
      {body?.length > 0 &&
        body.map((blok: any) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}
    </Flex>
  )
}

export default PageContent
