import React from 'react'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'
import { Flex, Box } from 'reflexbox'

import Highlight from 'src/storyblok/Components/Highlight/Highlight'
import Components from 'src/storyblok/Components'

import * as styles from './HeroGrid.module.scss'
import { Props } from './types'

const HeroGrid = ({ blok, isStart }: Props): JSX.Element => {
  const { highlights } = blok

  const gridWrapper = classnames(styles.wrapper, styles.grid)
  const flexWrapper = classnames(styles.wrapper, styles.flex)
  return (
    <SbEditable content={blok}>
      <Box className={gridWrapper}>
        {highlights?.length > 0 &&
          highlights.map((blok: any, index: any) => (
            <div
              key={`grid-${blok._uid}`}
              className={classnames(
                styles.col,
                index === 0
                  ? styles.col1
                  : index === 1
                  ? styles.col2
                  : index === 2
                  ? styles.col3
                  : index === 3
                  ? styles.col4
                  : ''
              )}
            >
              {React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
                preload: index === 0,
                size: index === 0 ? 'large' : null,
              })}
            </div>
          ))}
      </Box>
      <Box className={flexWrapper}>
        {highlights?.length > 0 && (
          <React.Fragment>
            <Flex className={classnames(styles.container, styles.large)}>
              <Highlight
                preload={true}
                blok={highlights[0]}
                size="large"
                htmlHeading="h1"
              />
            </Flex>
            <Box className={classnames(styles.container, styles.slider)}>
              <Flex>
                {highlights.map((blok: any, index: any) => {
                  return index > 0
                    ? React.createElement(Components(blok.component), {
                        key: blok._uid,
                        blok: blok,
                      })
                    : null
                })}
              </Flex>
            </Box>
          </React.Fragment>
        )}
      </Box>
    </SbEditable>
  )
}

export default HeroGrid
