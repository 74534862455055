import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'
import classnames from 'classnames'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import Image from 'src/components/UI/Image/Image'

import * as styles from './ContactPerson.module.scss'
import { Props } from './types'

const ContactPerson = ({ blok }: Props) => {
  const { image, name, title, email, phone } = blok

  return (
    <SbEditable content={blok}>
      <Flex flexDirection="column" width={1}>
        {image && image.filename && (
          <Image
            src={image.filename}
            alt={name}
            sizes={{
              mobile: '50vw',
              tablet: '25vw',
              desktop: '25vw',
            }}
          />
        )}
        <Box marginTop={[3]} className={styles.name} as="span">
          {name}
        </Box>
        <Box className={styles.title} as="span">
          {title}
        </Box>
        <Flex flexDirection={['column', null, null, 'row']}>
          {email && (
            <LinkComponent className={styles.email} to={`mailto:${email}`}>
              E-post
            </LinkComponent>
          )}
          {email && phone && (
            <Box
              marginLeft={1}
              as="span"
              className={classnames(styles.separator, 'hideMobile')}
            >
              /
            </Box>
          )}
          {phone && (
            <Box
              marginLeft={[0, null, null, 1]}
              as="span"
              className={styles.phone}
            >
              <LinkComponent className={styles.phone} to={`tel:${phone}`}>
                {phone}
              </LinkComponent>
            </Box>
          )}
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default ContactPerson
