import React, { useState, useEffect, useRef } from 'react'
import SbEditable from 'storyblok-react'
import { Box, Flex } from 'reflexbox'
import classnames from 'classnames'
import { useRecoilState } from 'recoil'
import { useLocation } from '@reach/router'

import { activePopupState } from 'src/recoil/atoms'
import FavoriteButton from 'src/components/Favorites/FavoriteButton/FavoriteButton'
import { getDataSourceEntrySlug } from 'src/helpers/getDataSourceData'
import Components from 'src/storyblok/Components'
import { InfoIcon, PrintIcon } from 'src/components/UI/Icon/Icon'
import Markdown from 'src/components/Markdown/Markdown'
import ButtonPrimary from 'src/components/UI/Button/ButtonPrimary/ButtonPrimary'
import { parseStoryblokProductToAlgolia } from 'src/algolia/clienthelpers'
import { getFlag } from 'src/helpers/getFlag'
import { getVolumeInMillieLiters } from 'src/helpers/getVolumeInMillieLiters'
import SEO, { ProductSEO } from 'src/components/SEO/SEO'
import { getSeo } from 'src/helpers/getSeo'
import {
  getCategoryName,
  getCategoryStructure,
} from 'src/helpers/getCategoryStructure'
import { InfoGrid } from 'src/components/Product/InfoGrid/InfoGrid'
import { ImageSection } from 'src/components/Product/ImageSection/ImageSection'
import { GoesWellWith } from 'src/components/Product/GoesWellWith/GoesWellWith'
import { AboutProduct } from 'src/components/Product/AboutProduct/AboutProduct'
import { Pairings } from 'src/components/Product/Pairings/Pairings'
import Sizepicker from 'src/components/Sizepicker/Sizepicker'
import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { gtagEvent } from 'src/components/UI/GoogleAnalytics/GoogleAnalytics'

import * as styles from './PageProduct.module.scss'
import { Props } from './types'

const PageProduct = (props: Props): JSX.Element => {
  const { blok, full_slug, storyID, name: productName } = props

  const [, setActivePopup] = useRecoilState(activePopupState)

  const algoliaProduct = parseStoryblokProductToAlgolia({
    full_slug,
    uuid: storyID,
    name: productName,
    content: blok,
  })

  const {
    name,
    category,
    labelNames,
    producerName,
    countryName,
    grapeName,
    volume,
    regionName,
    price,
    description,
    goesWellWithNames,
    year,
    media,
    id,
    status,
    status_text,
    url,
    ratings,
    producer,
    quote,
    product_description,
    product_taste,
    taste_profiles,
    hide_suggestions_in_header,
    suggestions_title,
    suggestions_text,
    suggestions_articles,
    globalProduct,
    content,
    assortmentName,
    relatedSizes,
    region,
    country,
    badge,
    sku,
    alcohol,
    sugar_content,
  } = algoliaProduct

  const { seo_data } = blok
  const { seo_title, seo_description, seo_image } = getSeo(seo_data, {
    description: description || '',
    image: media?.length > 0 && media[0] ? media[0] : '',
  })
  const alcoholPercentage =
    alcohol && alcohol > 0
      ? Math.round(parseFloat(alcohol) * 100 * 10) / 10 + '%'
      : 0
  const { about_image, video } = globalProduct
  const categoryName = getCategoryName(category)
  const categoryTree = getCategoryStructure(category)
  const Flag = getFlag(countryName)
  const volumeInMillieLiters = getVolumeInMillieLiters(volume)
  const producerLink = getDataSourceEntrySlug(producer)
  const countryLink = getDataSourceEntrySlug(country)

  const regionLinks =
    region && region.length > 0
      ? region.map((reg: any) => getDataSourceEntrySlug(reg))
      : []

  const regionNames =
    regionName && regionName.length > 0 ? regionName.join(', ') : ''

  const [readMore, setReadMore] = useState(
    description && description.length > 160
  )

  const printPage = () => {
    if (document.execCommand) {
      document.execCommand('print', false, null)
    }
    if (window?.print) {
      window.print()
    }
  }

  const elRef = useRef(null)
  let observer: any = null
  const [showSnackBar, setShowSnackBar] = useState(false)

  const observeHandler = (entries: any) => {
    for (const entry of entries) {
      if (entry.isIntersecting && observer) {
        setTimeout(() => {
          setShowSnackBar(false)
        }, 500)
      } else {
        setShowSnackBar(true)
      }
    }
  }

  useEffect(() => {
    if (typeof IntersectionObserver !== 'undefined' && !observer) {
      observer = new IntersectionObserver(observeHandler, {
        rootMargin: '0px 0px 0px 0px',
      })

      if (elRef && elRef.current) {
        observer.observe(elRef.current)
      }
    }

    return () => {
      observer.disconnect()
    }
  }, [])

  const seoDescription = `${categoryName} från ${countryName}${
    regionNames ? `, ${regionNames}` : ``
  }${
    volumeInMillieLiters ? ` • ${volumeInMillieLiters} ml` : ``
  } • Art.nr. ${sku} • Alkoholhalt ${alcoholPercentage} • Sockerhalt ${sugar_content} g/l `

  const locationHref = useLocation ? useLocation().href : ''

  const regionArray = regionNames.split(',')

  return (
    <SbEditable content={blok}>
      <React.Fragment>
        <ProductSEO
          description={seoDescription}
          url={full_slug}
          product={algoliaProduct}
        />
        <SEO
          url={full_slug}
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image ? seo_image : null}
        />
        <Flex
          className={classnames(
            styles.snackBar,
            showSnackBar ? styles.show : '',
            'hideDesktop'
          )}
          justifyContent="space-between"
          p={[4]}
        >
          <Box>
            <Box className={styles.snackTitle} marginBottom={[2]}>
              {name}
            </Box>
            <Box className={styles.snackPrice}>{price} Sek</Box>
          </Box>
          <ButtonPrimary
            disabled={status === 'allocated'}
            to={status === 'available' && url ? '' : url ? url : ''}
            onClick={
              status === 'available' && url
                ? () => setActivePopup({ url, name, price })
                : null
            }
          >
            {status === 'available'
              ? 'Köp'
              : status === 'unavailable'
              ? 'Visa'
              : status === 'order'
              ? 'Beställ'
              : 'Finns ej'}
          </ButtonPrimary>
        </Flex>
        <Flex
          width={1}
          flexWrap="wrap"
          className={styles.productHeader}
          ref={elRef}
        >
          <ImageSection
            image={media?.length > 0 ? media[0] : null}
            name={name}
            categoryTree={categoryTree}
            labels={labelNames}
            ratings={ratings}
            quote={quote}
            id={id}
            badge={badge}
          />
          <Flex
            width={[1, null, null, 1 / 2]}
            flexDirection="column"
            paddingX={[4, null, null, 20]}
            paddingY={[7, null, null, 22]}
            className={styles.productSection}
          >
            <FavoriteButton
              className={classnames(
                styles.favoriteButtonMobile,
                'hideDesktop',
                'hidePrint'
              )}
              item={{
                sku,
                name,
                price,
                image: media?.length > 0 ? media[0] : null,
                slug: full_slug,
                status,
                url,
                producerName,
              }}
            />
            <Flex
              alignItems="center"
              as="button"
              className={classnames(styles.printButton, 'hidePrint')}
              onClick={() => {
                printPage()
                gtagEvent({
                  action: name,
                  category: 'File - Download',
                  label: locationHref,
                })
              }}
            >
              <PrintIcon />
              <Box
                marginLeft={['6px']}
                as="span"
                className={styles.printButtonText}
              >{`Produktblad`}</Box>
            </Flex>
            {producerLink ? (
              <Box
                order="1"
                marginBottom={2}
                className={classnames(styles.producer, 'hidePrint')}
              >
                <LinkComponent to={producerLink}>{producerName}</LinkComponent>
              </Box>
            ) : (
              <Box
                order="1"
                as="span"
                marginBottom={2}
                className={classnames(styles.producer, 'hidePrint')}
              >
                {producerName}
              </Box>
            )}
            <Box order="1" marginBottom={[4]} className={styles.title} as="h1">
              {name}
            </Box>
            {(categoryName || countryName || volume) && (
              <Flex
                order="1"
                marginBottom={[1, null, null, 1]}
                className={classnames(styles.metaInformation, 'hidePrint')}
                alignItems="center"
              >
                {Flag && (
                  <div>
                    <Box
                      marginTop={['2px', null, null, '2px']}
                      marginRight={['6px', null, null, '6px']}
                    >
                      {Flag}
                    </Box>
                  </div>
                )}
                <Box>
                  <Box as="span">
                    {categoryName && categoryName}
                    {countryName &&
                      ` från ${countryName}${regionNames ? `, ` : ``}`}
                  </Box>
                  <Box as="span">
                    {regionNames && `${regionNames}`}
                    {volumeInMillieLiters
                      ? ` • ${volumeInMillieLiters} ml`
                      : ``}
                    {sku ? ` • Art.nr. ${sku}` : ``}
                    {alcoholPercentage
                      ? ` • Alkoholhalt ${alcoholPercentage}`
                      : ``}
                    {sugar_content ? ` • Sockerhalt ${sugar_content} g/l ` : ``}
                  </Box>
                </Box>
              </Flex>
            )}
            {price > 0 && (
              <Flex
                order="1"
                marginTop={[3]}
                marginBottom={[0, null, null, 2]}
                alignItems="center"
                className={classnames(styles.priceWrapper, 'hidePrint')}
              >
                <Box as="span" className={styles.price}>
                  {price} Sek
                </Box>
                <Box as="span" className={styles.tax} marginLeft={[1]}>
                  Ink. moms
                </Box>
              </Flex>
            )}
            {description && (
              <Box
                marginTop={[6, null, null, 0]}
                order={['2', null, null, '1']}
                className={classnames(styles.description, 'hidePrint')}
                as="p"
              >
                {readMore
                  ? description.substring(0, 160) + '... '
                  : description}
                {readMore && (
                  <Box
                    as="button"
                    display="inline-block"
                    className={classnames(styles.readMore, 'hidePrint')}
                    onClick={() => setReadMore(false)}
                  >
                    Läs mer
                  </Box>
                )}
              </Box>
            )}

            <Box
              className="hidePrint"
              order={['1', null, null, '1']}
              width={[1, null, null, 2 / 3]}
            >
              <Flex
                marginBottom={[4, null, null, 0]}
                marginTop={[2, null, null, 4]}
              >
                {relatedSizes && relatedSizes.length > 0 && (
                  <Sizepicker sizes={relatedSizes} activeSize={volume} />
                )}

                <ButtonPrimary
                  disabled={status === 'allocated'}
                  to={status === 'available' && url ? '' : url ? url : ''}
                  onClick={
                    status === 'available' && url
                      ? () => setActivePopup({ url, name, price })
                      : null
                  }
                >
                  {status === 'available'
                    ? 'Köp på Systembolaget'
                    : status === 'unavailable'
                    ? 'Visa på Systembolaget'
                    : status === 'order'
                    ? 'Beställ på Systembolaget'
                    : 'Ej tillgänglig'}
                </ButtonPrimary>
                <Box className="hideMobile" marginLeft={[4, null, null, 4]}>
                  <FavoriteButton
                    item={{
                      sku,
                      name,
                      price,
                      image: media?.length > 0 ? media[0] : null,
                      slug: full_slug,
                      status,
                      url,
                      producerName,
                    }}
                  />
                </Box>
              </Flex>

              {status_text && (
                <Flex
                  className={classnames(styles.statusText, 'hidePrint')}
                  paddingTop={[3]}
                  paddingX={[2]}
                  paddingBottom={[2]}
                  marginTop={[2, null, null, 4]}
                >
                  <Flex width="14px" flex="0 0 14px">
                    <InfoIcon />
                  </Flex>
                  <Box paddingLeft={[2]}>
                    <Markdown source={status_text} />
                  </Box>
                </Flex>
              )}
            </Box>
            {!hide_suggestions_in_header && (
              <Box className="hidePrint" order={['3', null, null, '1']}>
                {goesWellWithNames?.length > 0 && (
                  <Flex
                    order={['3', null, null, '1']}
                    marginTop={[7, null, null, 9]}
                    alignItems="center"
                    width={1}
                  >
                    <Box
                      className={classnames(styles.label, 'hidePrint')}
                      marginRight={[9]}
                    >
                      Passar till
                    </Box>

                    <Box>
                      <GoesWellWith
                        spacing
                        goesWellWith={goesWellWithNames.slice(0, 3)}
                      />
                    </Box>
                  </Flex>
                )}
              </Box>
            )}
            <Box
              order={['3', null, null, '1']}
              className={styles.infoGrid}
              marginTop={[7, null, null, 12]}
            >
              <InfoGrid
                country={countryName}
                countryLink={countryLink}
                grapes={grapeName?.length > 0 ? grapeName.join(', ') : ''}
                regions={regionArray}
                regionLinks={regionLinks}
                year={year}
                producer={producerName}
                producerLink={producerLink}
                assortment={assortmentName}
              />
            </Box>
            <Box className={styles.printInformation} order={2}>
              {/* {price > 0 && (
                <Flex marginBottom={'6px'}>
                  <Box
                    className={styles.printTitle}
                    marginRight={'2px'}
                  >{`Pris`}</Box>
                  <Box>
                    <Box as="span">{price} Sek</Box>
                    <Box as="span" marginLeft={[1]}>
                      Ink. moms
                    </Box>
                  </Box>
                </Flex>
              )} */}
              {producerName && (
                <Flex marginBottom={'6px'}>
                  <Box
                    className={styles.printTitle}
                    marginRight={'2px'}
                  >{`Producent`}</Box>
                  <Box>{producerName}</Box>
                </Flex>
              )}
              {sku && (
                <Flex marginBottom={'6px'}>
                  <Box
                    className={styles.printTitle}
                    marginRight={'2px'}
                  >{`Art.nr.`}</Box>
                  <Box>{sku}</Box>
                </Flex>
              )}
              {grapeName?.length > 0 && (
                <Flex marginBottom={'6px'}>
                  <Box
                    className={styles.printTitle}
                    marginRight={'2px'}
                  >{`Druvor`}</Box>
                  <Box>{grapeName.join(', ')}</Box>
                </Flex>
              )}
              {countryName && (
                <Flex marginBottom={'6px'}>
                  <Box
                    className={styles.printTitle}
                    marginRight={'2px'}
                  >{`Land`}</Box>
                  <Box>{countryName}</Box>
                </Flex>
              )}
              {regionNames && (
                <Flex marginBottom={'6px'}>
                  <Box
                    className={styles.printTitle}
                    marginRight={'2px'}
                  >{`Region`}</Box>
                  <Box>{regionNames}</Box>
                </Flex>
              )}
              {year && (
                <Flex marginBottom={'6px'}>
                  <Box
                    className={styles.printTitle}
                    marginRight={'2px'}
                  >{`Årgång`}</Box>
                  <Box>{year}</Box>
                </Flex>
              )}
              {alcoholPercentage && (
                <Flex marginBottom={'6px'}>
                  <Box
                    className={styles.printTitle}
                    marginRight={'2px'}
                  >{`Alkoholhalt`}</Box>
                  <Box>{alcoholPercentage}</Box>
                </Flex>
              )}
              {sugar_content && (
                <Flex marginBottom={'6px'}>
                  <Box
                    className={styles.printTitle}
                    marginRight={'2px'}
                  >{`Sockerhalt`}</Box>
                  <Box>{`${sugar_content} g/l`}</Box>
                </Flex>
              )}
              {/* {assortmentName && (
                <Flex marginBottom={'6px'}>
                  <Box
                    className={styles.printTitle}
                    marginRight={'2px'}
                  >{`Sortiment`}</Box>
                  <Box>{assortmentName}</Box>
                </Flex>
              )} */}
            </Box>
            <Box className={styles.printInformation} order={4}>
              {product_taste && (
                <Box className={styles.printBlock}>
                  <Box className={styles.printLabel}>{`Karaktär`}</Box>
                  <Box className={styles.printText}>
                    <Markdown source={product_taste} />
                  </Box>
                </Box>
              )}
              {product_description && (
                <Box className={styles.printBlock}>
                  <Box className={styles.printLabel}>{`Om vinet`}</Box>
                  <Box className={styles.printText}>
                    <Markdown source={product_description} />
                  </Box>
                </Box>
              )}
              {suggestions_text && (
                <Box className={styles.printBlock}>
                  <Box className={styles.printLabel}>{suggestions_title}</Box>
                  <Box className={styles.printText}>
                    <Markdown source={suggestions_text} />
                  </Box>
                </Box>
              )}
            </Box>
          </Flex>
          {quote && (
            <Box
              className={classnames(styles.quote, 'hideDesktop', 'hidePrint')}
              paddingX={4}
              paddingY={16}
              textAlign="center"
            >
              <Box as="blockquote">{`“${quote}”`}</Box>
            </Box>
          )}
        </Flex>
        <Box
          className={classnames(
            styles.printInformation,
            styles.dynamicInformation
          )}
        >
          {content?.length > 0 &&
            content.map((blok: any) => {
              return blok.component === 'quote_and_text' ||
                blok.component === 'image_and_text' ? (
                <Box key={blok._uid} className={styles.printBlock}>
                  {blok?.label && (
                    <Box className={styles.printLabel} marginBottom={1}>
                      {blok.label}
                    </Box>
                  )}
                  {blok?.text && (
                    <Box className={styles.printText} marginBottom={3}>
                      <Markdown source={blok.text} />
                    </Box>
                  )}
                </Box>
              ) : null
            })}
        </Box>
        {(product_description ||
          product_taste ||
          taste_profiles?.length > 0) && (
          <AboutProduct
            image={about_image}
            productDescription={product_description}
            productTaste={product_taste}
            tasteProfiles={taste_profiles}
          />
        )}
        {video?.length > 0 &&
          video.map((blok: any) => (
            <Box key={blok._uid} className="hidePrint">
              {React.createElement(Components(blok.component), {
                blok: blok,
              })}
            </Box>
          ))}
        {(suggestions_articles?.length > 0 ||
          suggestions_title ||
          suggestions_text) && (
          <Pairings
            articles={suggestions_articles}
            title={suggestions_title}
            text={suggestions_text}
            goesWellWith={goesWellWithNames}
          />
        )}
        <Box key={blok._uid} className={'hidePrint'}>
          {content?.length > 0 &&
            content.map((blok: any) => {
              return React.createElement(Components(blok.component), {
                key: blok._uid,
                blok: blok,
              })
            })}
        </Box>
      </React.Fragment>
    </SbEditable>
  )
}

export default PageProduct
