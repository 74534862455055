import React from 'react'
import classnames from 'classnames'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import ButtonGhost from 'src/components/UI/Button/ButtonGhost/ButtonGhost'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import { getLinkAttributes } from 'src/helpers/getLinkAttributes'
import Markdown from 'src/components/Markdown/Markdown'

import * as styles from './ClubInfo.module.scss'
import { Props } from './types'

const ClubInfo = ({ blok }: Props): JSX.Element => {
  const { title, label, info, theme } = blok

  const wrapperStyles = classnames(
    styles.wrapper,
    theme === 'rose'
      ? styles.roseTheme
      : theme === 'green'
      ? styles.greenTheme
      : ''
  )
  return (
    <SbEditable content={blok}>
      <Flex width={1} flexDirection={['column', null, null, 'row']}>
        {!info ||
          (info && info.length < 2 && (
            <Flex
              order={0}
              className={wrapperStyles}
              paddingX={[4, null, null, 19]}
              paddingY={[23, null, 27, 27]}
              flexDirection="column"
              width={[1, null, null, 1 / 2]}
              justifyContent="center"
            >
              <Flex
                flexDirection="column"
                width={[1, null, 9 / 12, 1]}
                marginLeft={[0, null, `${(1 / 12) * 100}vw`, 0]}
              >
                <Box
                  textAlign={['center', null, 'left']}
                  marginBottom={[4, null, 1]}
                  className={styles.label}
                  as="span"
                >
                  {label}
                </Box>
                <Box
                  textAlign={['center', null, 'left']}
                  className={styles.title}
                  as="h3"
                >
                  {title}
                </Box>
              </Flex>
            </Flex>
          ))}
        {info &&
          info.length > 0 &&
          info.map((section: any, index: number) => (
            <Flex
              key={section._uid}
              paddingX={[4, null, null, 19]}
              paddingY={[10, null, 18, 27]}
              width={[1, null, null, 1 / 2]}
              order={index === 1 ? [1, null, null, 2] : [2, null, null, 1]}
              justifyContent={info.length > 1 ? 'flex-start' : 'center'}
              className={styles.content}
              flexDirection="column"
            >
              <Flex
                flexDirection="column"
                width={[1, null, 9 / 12, 1]}
                marginLeft={[0, null, `${(1 / 12) * 100}vw`, 0]}
              >
                <Markdown source={section.markdown} />
              </Flex>
            </Flex>
          ))}
      </Flex>
    </SbEditable>
  )
}

export default ClubInfo
