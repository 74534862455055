import React from 'react'
import { Box } from 'reflexbox'

import { Props } from './types'
import MultiFilter from './MultiFilter/MultiFilter'
import RangeFilter from './RangeFilter/RangeFilter'

const getFacetName = (facetKey: string) => {
  let name = ''
  switch (facetKey) {
    case 'grape':
      name = 'Druva'
      break
    case 'country':
      name = 'Land'
      break
    case 'assortment':
      name = 'Sortiment'
      break
    case 'region':
      name = 'Region'
      break
    case 'producer':
      name = 'Producent'
      break
    case 'sustainable':
      name = 'Hållbart'
      break
    case 'goes_well_with':
      name = 'Passar till'
      break
    case 'category':
      name = 'Kategori'
      break
    case 'price':
      name = 'Pris'
      break
  }

  return name
}

const Filters = ({
  facets,
  activeFacets,
  availableFacets,
  setFilter,
  setRangeFilter,
  isFiltered,
}: Props) => {
  if (!facets) {
    return null
  }

  if (!isFiltered) {
    availableFacets = null
  }

  const activeTypes = Object.keys(activeFacets)
    .filter((key: string) => key !== 'search')
    .filter((key: string) => activeFacets[key].length > 0)

  return (
    <Box>
      {Object.keys(facets).length > 0 &&
        Object.keys(facets).map((facetKey: string) => {
          const values = facets[facetKey]
          const name = getFacetName(facetKey)
          if (facetKey === 'price') {
            if (values.min === values.max) return null
            return (
              <RangeFilter
                name={name}
                key={facetKey}
                activeItems={activeFacets[facetKey]}
                min={values.min}
                max={values.max}
                setFilter={setRangeFilter}
                facetKey={facetKey}
              />
            )
          }

          return (
            <MultiFilter
              availableItems={
                availableFacets && availableFacets[facetKey]
                  ? availableFacets[facetKey]
                  : availableFacets
                  ? {}
                  : null
              }
              acceptAll={
                activeTypes &&
                activeTypes.length === 1 &&
                activeTypes[0] === facetKey
              }
              name={name}
              key={facetKey}
              activeItems={activeFacets[facetKey]}
              items={values}
              facetKey={facetKey}
              setFilter={setFilter}
            />
          )
        })}
    </Box>
  )
}

export default Filters
