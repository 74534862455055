import React, { useEffect } from 'react'
import { Box } from 'reflexbox'
import SbEditable from 'storyblok-react'
// import * as styles from './HostedNewsroom.module.scss'
// import { Props } from './types'

const HostedNewsroom = (): JSX.Element => {
  useEffect(() => {
    if (document) {
      const s = document.createElement('script')
      s.type = 'text/javascript'
      s.async = true
      s.src = 'https://ward-wines-ab.mynewsdesk.com/hosted_newsroom.js'
      const i = document.getElementById('mnd-script')

      if (i) {
        i.parentNode.insertBefore(s, i)
      }
    }
  }, [])
  return (
    <Box>
      <Box id="mnd-script" />
    </Box>
  )
}

export default HostedNewsroom
