import React from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Markdown from 'src/components/Markdown/Markdown'
import { Container } from 'src/components/UI/Grid/Grid'
import Image from 'src/components/UI/Image/Image'

import * as styles from './TextAndImage.module.scss'
import { Props } from './types'

const TextAndImage = ({ blok }: Props): JSX.Element => {
  const { image, text, image_position } = blok

  return (
    <SbEditable content={blok}>
      <Flex
        flexDirection={['column', null, null, 'row']}
        className={styles.wrapper}
        paddingY={[0, null, null, 25]}
        alignItems="center"
      >
        <Box
          order={[1, null, null, image_position === 'left' ? 0 : 1]}
          width={[1, null, null, 1 / 2]}
        >
          <Image src={image.filename} alt={image.filename} />
        </Box>
        <Flex
          order={[0, null, null, image_position === 'left' ? 1 : 0]}
          alignItems="center"
          justifyContent="center"
          paddingY={[10, null, null, 0]}
          width={[1, null, null, 1 / 2]}
        >
          <Box width={[1, null, 10 / 12, 4.5 / 6]}>
            <Container>{text && <Markdown source={text} />}</Container>
          </Box>
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default TextAndImage
