import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { parseStoryblokProductToAlgolia } from 'src/algolia/clienthelpers'
import { Product } from 'src/globalTypes/types'
import ProductCard from 'src/components//ProductCard/ProductCard'

import { Container, Row, Column } from 'src/components/UI/Grid/Grid'

import { Props } from './types'
import * as styles from './ProductGrid.module.scss'
import ProductList from '../ProductList/ProductList'

const ProductGrid = ({ blok }: Props): JSX.Element => {
  const { title, products } = blok

  return (
    <SbEditable content={blok}>
      <Box
        paddingTop={[16]}
        paddingBottom={[10]}
        as="section"
        className={['hideMobile'].join(' ')}
      >
        <Container>
          <Flex
            justifyContent="center"
            alignItems="center"
            marginBottom={[10, null, null, 13]}
          >
            {title && <Box className={styles.title}>{title}</Box>}
          </Flex>
          <Row>
            {products &&
              products.length > 0 &&
              products.map((product: Product, index: number) => {
                const parsedProduct = product?.id
                  ? parseStoryblokProductToAlgolia(product)
                  : {}

                return (
                  <Column
                    key={`product-grid-${index}`}
                    width={[1 / 4]}
                    marginBottom={[4, null, null, 6]}
                  >
                    <ProductCard {...parsedProduct} />
                  </Column>
                )
              })}
          </Row>
        </Container>
      </Box>
      <Box className={'hideDesktop'}>
        <ProductList blok={blok} />
      </Box>
    </SbEditable>
  )
}

export default ProductGrid
