import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import { parseStoryblokProductToAlgolia } from 'src/algolia/clienthelpers'
import { Container, Row, Column } from 'src/components/UI/Grid/Grid'
import ProductCard from 'src/components//ProductCard/ProductCard'

import * as styles from './QuoteAndProduct.module.scss'
import { Props } from './types'

const QuoteAndProduct = ({ blok }: Props): JSX.Element => {
  const { label, quote, product } = blok

  const parsedProduct = product ? parseStoryblokProductToAlgolia(product) : null
  return (
    <SbEditable content={blok}>
      <Flex
        className={styles.wrapper}
        flexDirection={['column', null, null, 'row']}
      >
        <Box width={[1, null, null, 1 / 2]}>
          <Box className={styles.quoteWrapper}>
            <Flex
              className={styles.quoteContent}
              paddingX={[4, null, null, 20]}
              paddingY={[25, null, null, 0]}
              flexDirection="column"
              justifyContent="center"
            >
              {label && (
                <Box className={styles.label} marginBottom={[2, null, null, 2]}>
                  {label}
                </Box>
              )}
              {quote && (
                <Box className={styles.quote} as="blockquote">
                  {quote}
                </Box>
              )}
            </Flex>
          </Box>
        </Box>
        <Box width={[1, null, null, 1 / 2]}>
          <Box className={styles.productWrapper}>
            <Flex
              className={styles.productContent}
              paddingY={[10, null, null, 0]}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              {parsedProduct && (
                <Box width={[3 / 6]}>
                  <Box className={styles.productCard} padding={[4]}>
                    <ProductCard {...parsedProduct} />
                  </Box>
                </Box>
              )}
            </Flex>
          </Box>
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default QuoteAndProduct
