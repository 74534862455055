import React from 'react'
import { Flex, Box } from 'reflexbox'
import SbEditable from 'storyblok-react'

import MailchimpForm from 'src/components/MailchimpForm/MailchimpForm'
import Image from 'src/components/UI/Image/Image'

import * as styles from './NewsletterTwoColumns.module.scss'
import { Props } from './types'

const listDefault =
  'https://wardwines.us1.list-manage.com/subscribe/post?u=c3ae07bf83d2709cd54acc0a2&amp;id=dbbe41f3c8'
const listPremium =
  'https://wardwines.us1.list-manage.com/subscribe/post?u=c3ae07bf83d2709cd54acc0a2&amp;id=489c9d8f07'

const NewsletterTwoColumns = ({ blok }: Props): JSX.Element => {
  const {
    label,
    title,
    subtitle,
    policy,
    button_text,
    image,
    image_title,
    image_position,
    list,
  } = blok

  const mailchimpList = list === 'premium' ? listPremium : listDefault

  return (
    <SbEditable content={blok}>
      <Flex
        className={styles.wrapper}
        width={1}
        flexDirection={['column', null, null, 'row']}
      >
        <Flex
          order={image_position === 'left' ? 1 : 0}
          paddingX={[4, null, null, 19]}
          paddingY={[18, null, 20, 27]}
          flexDirection="column"
          minHeight={[0, null, null, '85vh']}
          width={[1, null, null, 1 / 2]}
          justifyContent="center"
        >
          {label && (
            <Box marginBottom={[4]} className={styles.label} as="span">
              {label}
            </Box>
          )}
          <Box marginBottom={[5]} className={styles.title} as="h3">
            {title}
          </Box>
          <Box marginBottom={[4]} className={styles.subtitle} as="span">
            {subtitle}
          </Box>
          <Flex className={styles.form} width={[1, null, 7 / 12, 1]}>
            <MailchimpForm
              url={mailchimpList}
              use={{ firstName: false, lastName: false }}
              align={'flex-start'}
              texts={{
                policy,
                button: button_text,
              }}
            />
          </Flex>
        </Flex>
        <Box
          order={image_position === 'left' ? 0 : 1}
          className={styles.imageContainer}
          width={[1, null, null, 1 / 2]}
        >
          <Flex
            paddingX={[4, null, null, 19]}
            paddingY={[23, null, 27, 27]}
            width={1}
            className={styles.content}
            alignItems="center"
          >
            <Box
              textAlign={['center', null, 'left']}
              width={[1, null, 9 / 12, 1]}
              as="p"
            >
              {image_title}
            </Box>
          </Flex>
          {image && image.filename && (
            <Image src={image.filename} alt={image_title} />
          )}
        </Box>
      </Flex>
    </SbEditable>
  )
}

export default NewsletterTwoColumns
