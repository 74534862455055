import React, { useEffect, useRef } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import LinkComponent from 'src/components/LinkComponent/LinkComponent'
import { ArrowRight } from 'src/components/UI/Icon/Icon'
import Image from 'src/components/UI/Image/Image'

import * as styles from './ProducerList.module.scss'
import { Props } from './types'

const renderItems = (items: any[]) => {
  const ref = useRef(null)
  useEffect(() => {
    let index = 0
    const children = ref.current.children
    setInterval(() => {
      if (children && children[index]) {
        children[index].style.opacity = 0
        children[index].style.zIndex = 0
        index++
        if (index >= children.length) {
          index = 0
        }
        if (children && children[index]) {
          children[index].style.zIndex = 1
          children[index].style.opacity = 1
        }
      }
    }, 8000)
  }, [])

  return (
    <div ref={ref}>
      {items &&
        items.map((item: any) => (
          <LinkComponent key={item._uid} to={`/${item.link.cached_url}`}>
            <Image
              src={item.logotype.filename}
              alt={item.internal_title}
              sizes={{
                mobile: '200px',
                tablet: '200px',
                desktop: '200px',
              }}
            />
          </LinkComponent>
        ))}
    </div>
  )
}

const ProducerList = ({ blok }: Props): JSX.Element => {
  const { image, label, text, logotypes, link_to_all } = blok
  const items = {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
  }

  let index = 0
  logotypes.forEach((logotype: any) => {
    items[index].push(logotype)
    index++
    if (index > 5) {
      index = 0
    }
  })
  return (
    <SbEditable content={blok}>
      <Flex
        className={styles.wrapper}
        as="section"
        flexDirection={['column', null, null, 'row']}
        width={1}
      >
        <Flex className={styles.imageSection} width={[1, null, null, 1 / 2]}>
          <Flex
            flexDirection="column"
            paddingX={[5, null, null, 19]}
            alignItems="flex-start"
            justifyContent="center"
            textAlign="left"
            className={styles.content}
          >
            <Box marginBottom={2} className={styles.label} as="span">
              {label}
            </Box>
            <Box className={styles.text} as="p">
              {text}
            </Box>
          </Flex>
          {image && <Image src={image.filename} alt={label} />}
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          width={[1, null, null, 1 / 2]}
          flexDirection="column"
          paddingTop={[4, null, null, 0]}
          paddingBottom={[8, null, null, 0]}
          paddingX={[4]}
        >
          <Flex flexDirection="column" width={[1, null, null, 4 / 6]}>
            <Box width={1} className={styles.grid}>
              <Box className={styles.item}>{renderItems(items[0])}</Box>
              <Box className={styles.item}>{renderItems(items[1])}</Box>
              <Box className={styles.item}>{renderItems(items[2])}</Box>
              <Box className={styles.item}>{renderItems(items[3])}</Box>
              <Box className={styles.item}>{renderItems(items[4])}</Box>
              <Box className={styles.item}>{renderItems(items[5])}</Box>
            </Box>
            {link_to_all && (
              <Flex
                marginTop={[10, null, null, 4]}
                justifyContent={['center', null, null, 'flex-end']}
              >
                <LinkComponent
                  className={styles.link}
                  to={link_to_all.cached_url}
                >
                  <Flex alignItems="center">
                    <Box marginRight={1} as="span">
                      {'Se alla'}
                    </Box>
                    <ArrowRight width={11} height={9} strokeWidth={3} />
                  </Flex>
                </LinkComponent>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </SbEditable>
  )
}

export default ProducerList
