import React, { useRef, useEffect } from 'react'
import classnames from 'classnames'
import { Flex, Box } from 'reflexbox'

import { ChevronDownIcon } from 'src/components/UI/Icon/Icon'

import * as styles from './FilterSelect.module.scss'
import { Props } from './types'

const FilterSelect = ({
  name,
  id,
  value,
  onChange,
  className,
  options,
}: Props): JSX.Element => {
  return (
    <Flex
      alignItems="center"
      width={1}
      className={classnames(styles.sort, className)}
    >
      <Box
        as="select"
        width={1}
        onChange={onChange}
        className={styles.sortSelect}
        name={name}
        id={id}
      >
        {options?.length > 0 &&
          options.map((option) => (
            <option
              key={option.value}
              selected={option.value === value}
              value={option.value}
            >
              {option.title}
            </option>
          ))}
      </Box>
      <Box className={styles.sortIcon} as="span">
        <ChevronDownIcon />{' '}
      </Box>
    </Flex>
  )
}

export default FilterSelect
