import React from 'react'
import { Flex, Box } from 'reflexbox'

import Filters from '../Filters/Filters'
import FiltersOverlay from '../FiltersOverlay/FiltersOverlay'
import ProductGrid from '../ProductGrid/ProductGrid'
import ProductGridFooter from '../ProductGridFooter/ProductGridFooter'
import Toolbar from '../Toolbar/Toolbar'
import { Column, Container, Row } from '../UI/Grid/Grid'
import * as styles from './ProductListing.module.scss'

const ProductListing = ({
  products,
  allFacets,
  filter,
  availableFacets,
  totalHits,
  showFilter,
  sort,
  gridType,
  isFiltered,
  onFilterChange,
  onRangeFilterChange,
  setShowFilter,
  setSort,
  setGrid,
  sortOptions,
  fetchNextPage,
  resetFilter,
  content,
}: any): JSX.Element => (
  <Container className={styles.products}>
    <Box className={styles.filtersMobile}>
      <FiltersOverlay
        facets={allFacets}
        activeFacets={filter}
        setFilter={onFilterChange}
        setRangeFilter={onRangeFilterChange}
        resetAllFilters={resetFilter}
        sort={sort}
        setSort={setSort}
        sortOptions={sortOptions}
        setShowFilter={setShowFilter}
        isFiltered={isFiltered}
        totalHits={totalHits}
        availableFacets={availableFacets}
      />
    </Box>
    <Box
      className={styles.toolbar}
      marginX={['-16px', null, null, '-24px']}
      paddingX={[4, null, null, 6]}
      paddingTop={[5, null, null, 0]}
    >
      <Toolbar
        activeFacets={filter}
        setFilter={onFilterChange}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        isFiltered={isFiltered}
        resetAllFilters={resetFilter}
        sort={sort}
        setSort={setSort}
        sortOptions={sortOptions}
        gridType={gridType}
        setGridType={setGrid}
      />
    </Box>
    <Row>
      {showFilter && (
        <Column width={[1, null, null, 1 / 4]}>
          <Box
            className={styles.filtersDesktop}
            paddingTop={[6, null, null, 6]}
          >
            <Filters
              facets={allFacets}
              setFilter={onFilterChange}
              setRangeFilter={onRangeFilterChange}
              activeFacets={filter}
              isFiltered={isFiltered}
              availableFacets={availableFacets}
            />
          </Box>
        </Column>
      )}
      <Column width={[1, null, null, showFilter ? 3 / 4 : 1]}>
        <Box paddingTop={[6, null, null, 6]}>
          {products?.length > 0 ? (
            <ProductGrid
              products={products}
              content={
                !isFiltered && content && content?.length > 0 ? content : null
              }
              gridType={showFilter ? 'condensed' : ''}
              gridTypeMobile={gridType === 'one' ? 'full' : ''}
            />
          ) : (
            <Flex justifyContent="center" paddingY={[4, null, null, 4]}>
              <Box maxWidth="720px" textAlign="center">
                <Box as="p">
                  {`Vi kan tyvärr inte hitta något som passar in på den
                  kombination.`}
                </Box>
                <Box as="p">
                  {`Gör en ny sökning, titta på våra topplistor eller
                sök bland recept för inspirerande vintips.`}
                </Box>
              </Box>
            </Flex>
          )}
        </Box>
      </Column>
    </Row>
    <ProductGridFooter
      totalHits={totalHits}
      loadedProducts={products.length}
      page={0}
      loadNextPage={fetchNextPage}
    />
  </Container>
)

export default ProductListing
